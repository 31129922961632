import { ClassificationsAnswerType, ClassificationsType, ClassifiersType, OptionsList } from "types/common";

export const arrayOfObjectsToOptionList = (
  arr: Array<{ [key: string]: any }>,
  textKey: string,
  valueKey: string
): OptionsList[] =>
  arr.map(
    (item: { [key: string]: string }): OptionsList => ({
      text: item[textKey],
      value: item[valueKey],
    })
  );

export const arrayOfStringsToOptionList = (arr: string[]): OptionsList[] =>
  arr.map(
    (item: string): OptionsList => ({
      text: item,
      value: item,
    })
  );

export const normalizeClassificationsToFormValues = (
  classifications: ClassificationsType[],
  classifiers: ClassifiersType[]
) => {
  const formValues = {};
  classifications.forEach((item) => {
    const classifier = classifiers.find((classifier) => classifier._id === item.classifier_id);
    if (classifier?.type === "radiobutton") {
      formValues[classifier.name] = {
        category: (item.answer[0] as ClassificationsAnswerType).category,
        classifierId: classifier._id,
        classifierName: classifier.name,
        type: "radiobutton",
        value: (item.answer[0] as ClassificationsAnswerType).value,
      };
    } else if (classifier?.type === "checkbox") {
      (item.answer as ClassificationsAnswerType[]).forEach((answer) => {
        formValues[answer.value] = {
          category: answer.category,
          classifierId: classifier._id,
          classifierName: classifier.name,
          type: "checkbox",
          value: answer.value,
        };
      });
    } else if (classifier?.type === "dropdown") {
      formValues[classifier.name] = item.answer;
    }
  });
  return formValues;
};
