import { API_URI, appName } from "utils/constants";
import { ActionType, DataSetType, ErrorType, LocaleLabelingResultType } from "types/common";
import { Store } from "redux";
import { createSelector } from "reselect";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { callApi } from "redux/api";
import { FormData as LabelingFormData } from "components/LabelingForm/LabelingForm";
import { stat } from "fs";

/* Constants */

export const moduleName = "dataset";

const prefix = `${appName}/${moduleName}`;

export const PUT_LOCAL_LABELING_RESULT = `${prefix}/PUT_LOCAL_LABELING_RESULT`;
export const FETCH_PROJECT_DATASET_REQUEST = `${prefix}/FETCH_PROJECT_DATASET_REQUEST`;
export const FETCH_PROJECT_DATASET_SUCCESS = `${prefix}/FETCH_PROJECT_DATASET_SUCCESS`;
export const FETCH_PROJECT_DATASET_FAILURE = `${prefix}/FETCH_PROJECT_DATASET_FAILURE`;

export const PUT_LOCAL_REVIEWING_RESULT = `${prefix}/PUT_LOCAL_REVIEWING_RESULT`;
export const FETCH_LABELED_DATASET_REQUEST = `${prefix}/FETCH_LABELED_DATASET_REQUEST`;
export const FETCH_LABELED_DATASET_SUCCESS = `${prefix}/FETCH_LABELED_DATASET_SUCCESS`;
export const FETCH_LABELED_DATASET_FAILURE = `${prefix}/FETCH_LABELED_DATASET_FAILURE`;

export const FETCH_REVIEWED_DATASET_REQUEST = `${prefix}/FETCH_REVIEWED_DATASET_REQUEST`;
export const FETCH_REVIEWED_DATASET_SUCCESS = `${prefix}/FETCH_REVIEWED_DATASET_SUCCESS`;
export const FETCH_REVIEWED_DATASET_FAILURE = `${prefix}/FETCH_REVIEWED_DATASET_FAILURE`;

/* Reducer */

interface State {
  error: ErrorType | null;
  isFetching: boolean;
  entities: DataSetType[] | null;
  labeled: DataSetType[] | null;
  reviewed: DataSetType[] | null;
}

const initialState: State = {
  error: null,
  isFetching: false,
  entities: null,
  labeled: null,
  reviewed:null
};

export default function reducer(state: State = initialState, action: ActionType) {
  const { type, payload, error } = action;
  switch (type) {
    case FETCH_PROJECT_DATASET_REQUEST:
    case FETCH_LABELED_DATASET_REQUEST:
      return Object.assign({}, state, {
        error: null,
        isFetching: true,
      });

    case FETCH_PROJECT_DATASET_SUCCESS:
      return Object.assign({}, state, {
        entities: payload,
        error: null,
        isFetching: false,
      });

    case FETCH_LABELED_DATASET_SUCCESS:
      return Object.assign({}, state, {
        labeled: payload,
        error: null,
        isFetching: false,
      });

    case PUT_LOCAL_LABELING_RESULT: {
      const newDataset = state.entities?.slice() || [];
      newDataset[payload.index] = Object.assign({}, newDataset[payload.index], {
        formData: payload.formData,
      });
      return Object.assign({}, state, {
        entities: newDataset,
        error: null,
        isFetching: false,
      });
    }

    case PUT_LOCAL_REVIEWING_RESULT: {
      const newDataset = state.labeled?.slice() || [];
      newDataset[payload.index] = Object.assign({}, newDataset[payload.index], {
        label: {
          classifications: payload.formData,
        },
      });
      return Object.assign({}, state, {
        labeled: newDataset,
        error: null,
        isFetching: false,
      });
    }

    case FETCH_PROJECT_DATASET_FAILURE:
    case FETCH_LABELED_DATASET_FAILURE:
      return Object.assign({}, state, {
        error,
        isFetching: false,
      });

    default:
      return state;
  }
}

/* Selectors */

const stateSelector = (state: Store) => state[moduleName];

export const selectCurrentDataSet = createSelector(stateSelector, (state: State) => state.entities);

export const selectLabeledDataSet = createSelector(stateSelector, (state: State) => state.labeled);

export const selectReviewedDataSet = createSelector(stateSelector, (state: State) => state.reviewed);


export const selectCurrentDataSetIsFetching = createSelector(stateSelector, (state: State) => state.isFetching);

export const selectCurrentDataSetError = createSelector(stateSelector, (state: State) => state.error);

/* Actions */

export function fetchCurrentProjectDataSet(id: string) {
  return {
    payload: id,
    type: FETCH_PROJECT_DATASET_REQUEST,
  };
}
export function fetchCurrentProjectDataSetSuccess(payload: DataSetType) {
  return {
    payload,
    type: FETCH_PROJECT_DATASET_SUCCESS,
  };
}
export function fetchCurrentProjectDataSetFailure(error: ErrorType) {
  return {
    error,
    type: FETCH_PROJECT_DATASET_FAILURE,
  };
}

export function fetchLabeledDataSet(id: string) {
  return {
    payload: id,
    type: FETCH_LABELED_DATASET_REQUEST,
  };
}
export function fetchLabeledDataSetSuccess(payload: DataSetType) {
  return {
    payload,
    type: FETCH_LABELED_DATASET_SUCCESS,
  };
}
export function fetchLabeledDataSetFailure(error: ErrorType) {
  return {
    error,
    type: FETCH_LABELED_DATASET_FAILURE,
  };
}
export function fetchReviewedDataSet(id: string) {
  return {
    payload: id,
    type: FETCH_REVIEWED_DATASET_REQUEST,
  };
}
export function fetchReviewedDataSetSuccess(payload: DataSetType) {
  return {
    payload,
    type: FETCH_REVIEWED_DATASET_SUCCESS,
  };
}
export function fetchReviewedDataSetFailure(error: ErrorType) {
  return {
    error,
    type: FETCH_REVIEWED_DATASET_FAILURE,
  };
}
export function putLocaleLabelingResult(payload: LocaleLabelingResultType<LabelingFormData>) {
  return {
    payload,
    type: PUT_LOCAL_LABELING_RESULT,
  };
}

export function putLocaleReviewingResult(payload: LocaleLabelingResultType<LabelingFormData>) {
  return {
    payload,
    type: PUT_LOCAL_REVIEWING_RESULT,
  };
}

/* Sagas */

function* fetchCurrentProjectDataSetSaga({ payload }: ActionType): SagaIterator {
  try {
    const response = yield call(ApiFetchCurrentProjectDataSet, payload);
    yield put(fetchCurrentProjectDataSetSuccess(response));
  } catch (error) {
    yield put(fetchCurrentProjectDataSetFailure(error));
  }
}

function* fetchLabeledDataSetSaga({ payload }: ActionType): SagaIterator {
  try {
    const response = yield call(ApiFetchLabeledDataSet, payload);
    yield put(fetchLabeledDataSetSuccess(response));
  } catch (error) {
    yield put(fetchLabeledDataSetFailure(error));
  }
}
function* fetchReviewedDataSetSaga({ payload }: ActionType): SagaIterator {
  try {
    const response = yield call(ApiFetchReviewedDataSet, payload);
    yield put(fetchReviewedDataSetSuccess(response));
  } catch (error) {
    yield put(fetchReviewedDataSetFailure(error));
  }
}

export const saga = function* () {
  yield all([
    takeEvery(FETCH_PROJECT_DATASET_REQUEST, fetchCurrentProjectDataSetSaga),
    takeEvery(FETCH_LABELED_DATASET_REQUEST, fetchLabeledDataSetSaga),
    takeEvery(FETCH_REVIEWED_DATASET_REQUEST, fetchReviewedDataSetSaga),
  ]);
};

/* API */

function* ApiFetchCurrentProjectDataSet(id: string) {
  return yield call(callApi, API_URI + "labels/" + id + "?status=queue");
}

function* ApiFetchLabeledDataSet(id: string) {
  let status = window.location.href.split('/')[window.location.href.split('/').length - 1];
  let callStatus = 'labeled';
  if(status.toLowerCase() == 'approved')
    callStatus = 'reviewed'
  return yield call(callApi, API_URI + "labels/" + id + "?status="+callStatus);
}

function* ApiFetchReviewedDataSet(id: string) {
  return yield call(callApi, API_URI + "labels/" + id + "?status=reviewed");
}

