import { API_URI, appName } from "utils/constants";
import { ActionType, ErrorType, ProjectsInfoType } from "types/common";
import { Store } from "redux";
import { createSelector } from "reselect";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { callApi } from "redux/api";

/* Constants */

export const moduleName = "labeledData";

const prefix = `${appName}/${moduleName}`;

export const FETCH_LABELED_DATA_REQUEST = `${prefix}/FETCH_LABELED_DATA_REQUEST`;
export const FETCH_LABELED_DATA_SUCCESS = `${prefix}/FETCH_LABELED_DATA_SUCCESS`;
export const FETCH_LABELED_DATA_FAILURE = `${prefix}/FETCH_LABELED_DATA_FAILURE`;

/* Reducer */

interface State {
  error: ErrorType | null;
  isFetching: boolean;
}

const initialState: State = {
  error: null,
  isFetching: false,
};

export default function reducer(state: State = initialState, action: ActionType) {
  const { type, error } = action;

  switch (type) {
    case FETCH_LABELED_DATA_REQUEST:
      return Object.assign({}, state, {
        error: null,
        isFetching: true,
      });

    case FETCH_LABELED_DATA_SUCCESS:
      return Object.assign({}, state, {
        error: null,
        isFetching: false,
      });

    case FETCH_LABELED_DATA_FAILURE:
      return Object.assign({}, state, {
        error,
        isFetching: false,
      });

    default:
      return state;
  }
}

/*Selectors */

const stateSelector = (state: Store) => state[moduleName];

export const selectLabeledDataIsFetching = createSelector(stateSelector, (state: State) => state.isFetching);

export const selectLabeledDataError = createSelector(stateSelector, (state: State) => state.error);

/* Actions */

export function fetchLabeledData(id: string) {
  return {
    payload: id,
    type: FETCH_LABELED_DATA_REQUEST,
  };
}
export function fetchLabeledDataSuccess(payload: ProjectsInfoType) {
  return {
    payload,
    type: FETCH_LABELED_DATA_SUCCESS,
  };
}
export function fetchLabeledDataFailure(error: ErrorType) {
  return {
    error,
    type: FETCH_LABELED_DATA_FAILURE,
  };
}

/* Sagas */

function* fetchLabeledDataSaga({ payload }: ActionType): SagaIterator {
  try {
    const response = yield call(ApiFetchLabeledData, payload);
    yield put(fetchLabeledDataSuccess(response));
  } catch (error) {
    yield put(fetchLabeledDataFailure(error));
  }
}

function* loadLabeledDataSaga({ payload }: ActionType): SagaIterator {
  try {
    const jsonStr = JSON.stringify(payload, null, 4);
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(jsonStr);
    const link = document.createElement("a");
    const date = new Date();
    link.download = "Algoface_labeledData" + date.toISOString().replace(".", "_") + ".json";
    link.href = dataStr;
    link.click();
    link.remove();
  } catch (error) {
    yield put(fetchLabeledDataFailure(error));
  }
}

export const saga = function* () {
  yield all([
    takeEvery(FETCH_LABELED_DATA_REQUEST, fetchLabeledDataSaga),
    takeEvery(FETCH_LABELED_DATA_SUCCESS, loadLabeledDataSaga),
  ]);
};

/* API */

function* ApiFetchLabeledData(id: string) {
  return yield call(callApi, API_URI + `projects/${id}/get-json-file`);
}
