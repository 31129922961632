import React from "react";
import { FieldArrayFieldsProps } from "redux-form/lib/FieldArray";
import { Button, Header, Input } from "semantic-ui-react";
import { Field } from "redux-form";
import SemanticFormField from "components/SemanticFormField/SemanticFormField";
import { requiredName } from "utils/validation";

import styles from "./DropdownClassifierOptionsFieldGroup.module.scss";

export default function DropdownClassifierOptionsFieldGroup(props: any) {
  const { fields } = props;

  const addItem = () => {
    fields.push({});
  };

  const renderFormItem = (options: string, index: number, fields: FieldArrayFieldsProps<string>) => {
    function removeItem() {
      fields.remove(index);
    }

    return (
      <div className={styles.fieldWrapper} key={index}>
        <Field
          name={`${options}.option`}
          component={SemanticFormField}
          as={Input}
          type="text"
          placeholder={`Option ${index + 1}`}
          validate={[requiredName]}
        />
        <div className={styles.removeButtonWrapper}>
          <Button onClick={removeItem} negative={true} type="button">
            Remove
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.root}>
      <Header as="h4">Options</Header>
      <div className="form-items">{fields.map(renderFormItem)}</div>
      <Button onClick={addItem} type="button">
        Add Option
      </Button>
    </div>
  );
}
