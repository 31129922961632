import { SagaIterator } from "redux-saga";
import { call, select, put } from "redux-saga/effects";

import { fetchSignOut, selectAuthAccessToken } from "ducks/authorization";

export function* callApi(endpoint: string, data?: object, method: string = "GET", headers: object = {}): SagaIterator {
  try {
    const apikey = yield select(selectAuthAccessToken);

    if (apikey) {
      Object.assign(headers, { accesstoken: apikey });
    }
    if (!(data instanceof FormData)) {
      Object.assign(headers, { "Content-Type": "application/json", cors: "no-cors" });
    }

    const request = {
      headers: { ...headers },
      method,
    };

    if (data) {
      Object.assign(request, {
        body: data instanceof FormData ? data : JSON.stringify(data),
      });
    }

    const responsePromise = yield call(fetch, endpoint, request);

    const responseData = yield responsePromise.json();

    if (responseData.type === "ERROR") {
      if (!!responseData.payload.msg) {
        if (responseData.payload.msg === "accesstoken validation failed") {
          yield put(fetchSignOut());
        }
        throw new Error(responseData.payload.msg);
      }
      throw new Error(responseData.payload.msg);
    }

    return responseData.payload.data;
  } catch (error) {
    throw error;
  }
}
