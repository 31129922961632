// PRODUCTION CONGNITO CREDENTIALS
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const awsmobile = {
    aws_project_region: "us-east-1",
    aws_cognito_region: "us-east-1",
    aws_cognito_identity_pool_id: "f685aec4-27a9-430f-8b5e-2a35cf755248",
    aws_user_pools_id: "us-east-1_1wt1Tdjhj",
    aws_user_pools_web_client_id: "3sin3e2tcs3rgj0b6plemqd67s",
};

// STAGING CONGNITO CREDENTIALS
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
// const awsmobile = {
//     aws_project_region: "us-east-1",
//     aws_cognito_region: "us-east-1",
//     aws_cognito_identity_pool_id: "68073977-4ac5-4747-8482-a9e9129abf3b",
//     aws_user_pools_id: "us-east-1_ipHUTRLCd",
//     aws_user_pools_web_client_id: "7qn1shb8ob12k6i34anqje6fj9",
// };
export default awsmobile;