import React, { Component } from "react";
import { Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import onClickOutside from "react-onclickoutside";

import { fetchSignOut } from "ducks/authorization";
import Logo from "static/img/logo.png";
import { ActionType } from "types/common";

import styles from "./Header.module.scss";
import { NavLink } from "react-router-dom";
import { ROUTES } from "utils/constants";

interface Props {
  fetchSignOut: () => ActionType;
}

interface State {
  isOpen: boolean;
}

class Header extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  public handleClickOutside = () => {
    this.setState({ isOpen: false });
  };

  public toggleIsOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  public signOut = () => {
    this.setState({ isOpen: false });
    this.props.fetchSignOut();
  };

  public render() {
    const { isOpen } = this.state;

    return (
      <div className={styles.root}>
        <img className={styles.logo} src={Logo} alt="Logo" />
        <div className={styles.signOutBtn} onClick={this.toggleIsOpen}>
          <Icon name="bars" size="big" />
        </div>
        {isOpen && (
          <div className={styles.menu}>
            <NavLink className={styles.link} to={ROUTES.projects}>
              Projects
            </NavLink>
            <div className={styles.link} onClick={this.signOut}>
              Sign Out
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  fetchSignOut,
};

export default connect(null, mapDispatchToProps)(onClickOutside(Header));
