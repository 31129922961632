import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Header as HeaderTag, Loader } from "semantic-ui-react";
import { Store } from "redux";
import { Link } from "react-router-dom";

import { fetchProjects, selectIsProjectsFetching, selectProjects } from "ducks/projects";
import { ActionType, ProjectsType } from "types/common";
import ProjectsList from "components/ProjectsList/ProjectsList";
import { ROUTES } from "utils/constants";

import styles from "./Projects.module.scss";

interface Props {
  projects: ProjectsType[] | null;
  isProjectsFetching: boolean;
  fetchProjects: () => ActionType;
}

class Projects extends Component<Props> {
  public componentDidMount() {
    this.props.fetchProjects();
  }

  public render() {
    const { projects, isProjectsFetching } = this.props;

    return (
      <>
        <div className={styles.headerWrapper}>
          <HeaderTag as="h1">Projects</HeaderTag>
          <Link to={ROUTES.createProject}>
            <Button primary={true}>+ Add a project</Button>
          </Link>
        </div>
        {isProjectsFetching && <Loader active={true} />}
        {!!projects && <ProjectsList projects={projects} />}
      </>
    );
  }
}

const mapStateToProps = (state: Store) => ({
  projects: selectProjects(state),
  isProjectsFetching: selectIsProjectsFetching(state),
});

const mapDispatchToProps = {
  fetchProjects,
};

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
