import React from "react";
import { Header } from "semantic-ui-react";

import CreateProjectForm from "components/CreateProjectForm/CreateProjectForm";

export default function CreateProject() {
  return (
    <>
      <Header as={"h1"}>Add a project</Header>
      <CreateProjectForm />
    </>
  );
}
