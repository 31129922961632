import React from "react";
import { Input, Form, Label } from "semantic-ui-react";
import { Dispatch } from "redux";
import { WrappedFieldInputProps, WrappedFieldMetaProps } from "redux-form";

import { SimpleHashMapType } from "types/common";

interface Props {
  as: React.ComponentType<any>;
  input: WrappedFieldInputProps;
  type: string;
  label: string;
  placeholder: string;
  checked?: (value: string) => boolean;
  semanticSimpleInput: boolean;
  radio: boolean;
  meta: WrappedFieldMetaProps;
  translates?: SimpleHashMapType;
  dispatch: Dispatch;
}

function SemanticFormField({
  input,
  type,
  label,
  placeholder,
  translates,
  checked,
  dispatch,
  semanticSimpleInput,
  radio,
  meta: { touched, error },
  as: As = Input,
  ...rest
}: Props) {
  function handleChange(e: React.SyntheticEvent, target: { value: string; checked: boolean }) {
    let value: string | boolean = "";
    if (semanticSimpleInput) {
      value = target.checked !== undefined ? target.checked : !!target.value;
    } else {
      value = !!target.value ? target.value : "";
    }

    return input.onChange(value);
  }

  function handleBlur() {
    return input.onBlur(input.value);
  }

  return (
    <Form.Field>
      <As
        {...input}
        {...rest}
        value={!semanticSimpleInput ? input.value : input.checked}
        error={!semanticSimpleInput ? !!error && touched : undefined}
        label={label}
        type={type}
        checked={semanticSimpleInput ? (radio ? checked && checked(input.value) : input.checked) : undefined}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {error && touched && (
        <Label basic={true} color="red" pointing="above" size="tiny">
          {error}
        </Label>
      )}
    </Form.Field>
  );
}

export default SemanticFormField;
