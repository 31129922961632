import { SubmissionError } from "redux-form";
import { Store } from "redux";
import { createSelector } from "reselect";
import { SagaIterator } from "redux-saga";
import { put, call, all, takeEvery, take, select } from "redux-saga/effects";
import Auth from "@aws-amplify/auth";

import { appName } from "utils/constants";
import { ActionType, ErrorType, FormSubmitActionPayloadType } from "types/common";
import { FormData as SignInFormData } from "components/SignInForm/SignInForm";

/* Constants */

export const moduleName = "auth";

const prefix = `${appName}/${moduleName}`;

export const FORM_SUBMIT_SIGN_IN = `${prefix}/FORM_SUBMIT_SIGN_IN`;
export const FETCH_SIGN_IN_REQUEST = `${prefix}/FETCH_SIGN_IN_REQUEST`;
export const FETCH_SIGN_IN_SUCCESS = `${prefix}/FETCH_SIGN_IN_SUCCESS`;
export const FETCH_SIGN_IN_FAILURE = `${prefix}/FETCH_SIGN_IN_FAILURE`;

export const FETCH_SIGN_OUT_REQUEST = `${prefix}/FETCH_SIGN_OUT_REQUEST`;
export const FETCH_SIGN_OUT_SUCCESS = `${prefix}/FETCH_SIGN_OUT_SUCCESS`;
export const FETCH_SIGN_OUT_FAILURE = `${prefix}/FETCH_SIGN_OUT_FAILURE`;

/* Reducer */

interface State {
  error: ErrorType | null;
  isFetching: boolean;
  entities: any;
}

const initialState: State = {
  error: null,
  isFetching: false,
  entities: null,
};

export default function reducer(state: State = initialState, action: ActionType) {
  const { type, payload, error } = action;

  switch (type) {
    case FORM_SUBMIT_SIGN_IN:
    case FETCH_SIGN_IN_REQUEST:
      return Object.assign({}, state, {
        error: null,
        isFetching: true,
      });

    case FETCH_SIGN_IN_SUCCESS:
      return Object.assign({}, state, {
        entities: payload,
        error: null,
        isFetching: false,
      });
    case FETCH_SIGN_OUT_SUCCESS:
      return Object.assign({}, state, {
        entities: null,
        error: null,
        isFetching: false,
      });

    case FETCH_SIGN_IN_FAILURE:
      return Object.assign({}, state, {
        error,
        isFetching: false,
      });

    default:
      return state;
  }
}

/*Selectors */

const stateSelector = (state: Store) => state[moduleName];

export const selectAuthEntities = createSelector(stateSelector, (state: State) => state.entities);

export const selectUserAttributes = createSelector(selectAuthEntities, (entities) => entities.attributes);

export const selectAuthAccessToken = createSelector(
  stateSelector,
  (state: State) => state.entities.signInUserSession.idToken.jwtToken
);

export const selectAuthIsFetching = createSelector(stateSelector, (state: State) => state.isFetching);

export const selectAuthError = createSelector(stateSelector, (state: State) => state.error);

/* Action Creators */

export function formSubmitSignIn(payload: FormSubmitActionPayloadType<SignInFormData>) {
  return {
    payload,
    type: FORM_SUBMIT_SIGN_IN,
  };
}
export function fetchSignIn(payload: SignInFormData) {
  return {
    payload,
    type: FETCH_SIGN_IN_REQUEST,
  };
}
export function fetchSignInSuccess(payload: any) {
  return {
    payload,
    type: FETCH_SIGN_IN_SUCCESS,
  };
}
export function fetchSignInError(error: ErrorType) {
  return {
    error,
    type: FETCH_SIGN_IN_FAILURE,
  };
}

export function fetchSignOut() {
  return {
    type: FETCH_SIGN_OUT_REQUEST,
  };
}
export function fetchSignOutSuccess() {
  return {
    type: FETCH_SIGN_OUT_SUCCESS,
  };
}
export function fetchSignOutError(error: ErrorType) {
  return {
    error,
    type: FETCH_SIGN_OUT_FAILURE,
  };
}

/* Sagas */

function* formSubmitSignInSaga({ payload }: ActionType): SagaIterator {
  const { resolve, reject, formData } = payload;
  yield put(fetchSignIn(formData));

  const fetchSignInResult = yield take([FETCH_SIGN_IN_SUCCESS, FETCH_SIGN_IN_FAILURE]);

  if (fetchSignInResult.type === FETCH_SIGN_IN_SUCCESS) {
    yield call(resolve);
  } else if (fetchSignInResult.type === FETCH_SIGN_IN_FAILURE) {
    const error = yield select(selectAuthError);

    yield call(reject, new SubmissionError({ _error: error.message || error }));
  }
}
function* fetchSignInSaga({ payload }: ActionType): SagaIterator {
  try {
    const { email, password } = payload;
    yield call([Auth, "signIn"], {
      username: email,
      password,
    });
    const result = yield call([Auth, "currentAuthenticatedUser"], {
      bypassCache: false,
    });
    yield put(
      fetchSignInSuccess({
        attributes: result.attributes,
        signInUserSession: result.signInUserSession,
      })
    );
  } catch (error) {
    yield put(fetchSignInError(error));
  }
}

function* fetchSignOutSaga(): SagaIterator {
  try {
    yield call([Auth, "signOut"]);
    yield put(fetchSignOutSuccess());
  } catch (error) {
    yield put(fetchSignOutError(error));
  }
}

export const saga = function* () {
  yield all([
    takeEvery(FORM_SUBMIT_SIGN_IN, formSubmitSignInSaga),
    takeEvery(FETCH_SIGN_IN_REQUEST, fetchSignInSaga),
    takeEvery(FETCH_SIGN_OUT_REQUEST, fetchSignOutSaga),
  ]);
};
