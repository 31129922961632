import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import authorizationModule, { moduleName as authorizationModuleName } from "ducks/authorization";
import projectsModule, { moduleName as projectsModuleName } from "ducks/projects";
import currentProjectModule, { moduleName as currentProjectModuleName } from "ducks/currentProject";
import datasetModule, { moduleName as datasetModuleName } from "ducks/dataset";
import loadDatasetModule, { moduleName as loadDatasetModuleName } from "ducks/loadDataset";
import labelingModule, { moduleName as labelingModuleName } from "ducks/labeling";
import classifiersModule, { moduleName as classifiersModuleName } from "ducks/classifiers";
import labeledDataModule, { moduleName as labeledDataModuleName } from "ducks/labeledData";
import usersModule, { moduleName as usersModuleName } from "ducks/users";
import currentProjectUsersModule, { moduleName as currentProjectUsersModuleName } from "ducks/currentProjectUsers";

// Add all reducers here.
export default combineReducers({
  form,
  [authorizationModuleName]: authorizationModule,
  [projectsModuleName]: projectsModule,
  [currentProjectModuleName]: currentProjectModule,
  [datasetModuleName]: datasetModule,
  [loadDatasetModuleName]: loadDatasetModule,
  [labelingModuleName]: labelingModule,
  [classifiersModuleName]: classifiersModule,
  [classifiersModuleName]: classifiersModule,
  [labeledDataModuleName]: labeledDataModule,
  [usersModuleName]: usersModule,
  [currentProjectUsersModuleName]: currentProjectUsersModule,
});
