import React, { Component } from "react";
import { Store } from "redux";
import { connect } from "react-redux";
import { Button, Header, Message, Table } from "semantic-ui-react";

import {
  ActionType,
  AddUserToProjectArguments,
  CurrentUserType,
  DeleteUserToProjectArguments,
  ErrorType,
  UserType,
} from "types/common";
import { fetchUsers, selectUsersEntities } from "ducks/users";
import AddUserToProject from "components/AddUserToProject/AddUserToProject";
import { deleteUserFromProject, putUserProjectRole, selectCurrentProjectUsersError } from "ducks/currentProjectUsers";
import ProjectUsersListItem from "components/ProjectUsersListItem/ProjectUsersListItem";

import styles from "./ProjectUsers.module.scss";

interface Props {
  projectId: string;
  currentUsers: CurrentUserType[];
  users: UserType[] | null;
  currentProjectUsersError: ErrorType | null;
  fetchUsers: () => ActionType;
  deleteUserFromProject: (payload: DeleteUserToProjectArguments) => ActionType;
  putUserProjectRole: (payload: AddUserToProjectArguments) => ActionType;
}

interface State {
  isAddUser: boolean;
}

class ProjectUsers extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isAddUser: false,
    };
  }

  public componentDidMount() {
    this.props.fetchUsers();
  }

  public addUser = () => this.setState({ isAddUser: true });
  public resetAddUser = () => this.setState({ isAddUser: false });

  public deleteUser = (projectId: string, id: string) => () => {
    this.props.deleteUserFromProject({
      user_id: id,
      projectId,
    });
  };

  public updateUser = (projectId: string, id: string, email: string) => (role: string) => {
    this.props.putUserProjectRole({
      user_id: id,
      email,
      projectId,
      access_type: role,
    });
  };

  public filterUsersNotInProject = () => {
    const { currentUsers, users } = this.props;

    return users?.filter((user) => {
      let isInProject = false;
      currentUsers.forEach((currentUser) => {
        if (currentUser.user_id === user.user_id) {
          isInProject = true;
        }
      });
      return !isInProject;
    });
  };

  public renderUsers = () =>
    this.props.currentUsers.map((user) => {
      return (
        <ProjectUsersListItem
          key={user.user_id}
          user={user}
          deleteUser={this.deleteUser(this.props.projectId, user.user_id)}
          updateUser={this.updateUser(this.props.projectId, user.user_id, user.email)}
        />
      );
    });

  public render() {
    const { users, currentProjectUsersError } = this.props;
    const { isAddUser } = this.state;

    return (
      <div className={styles.root}>
        <div className={styles.header}>
          <Header as="h3" className={styles.headerText}>
            {isAddUser ? "Add user" : "Current users"}
          </Header>
          <Button primary={!isAddUser} onClick={isAddUser ? this.resetAddUser : this.addUser}>
            {isAddUser ? "Back" : "Add new"}
          </Button>
        </div>
        {!!currentProjectUsersError && (
          <Message negative={true}>{currentProjectUsersError.message || currentProjectUsersError}</Message>
        )}
        {!isAddUser && (
          <Table celled={true} padded={true}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Role</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{this.renderUsers()}</Table.Body>
          </Table>
        )}
        {isAddUser && !!users && <AddUserToProject users={this.filterUsersNotInProject() || []} />}
      </div>
    );
  }
}

const mapStateToProps = (state: Store) => ({
  users: selectUsersEntities(state),
  currentProjectUsersError: selectCurrentProjectUsersError(state),
});

const mapDispatchToProps = {
  fetchUsers,
  deleteUserFromProject,
  putUserProjectRole,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectUsers);
