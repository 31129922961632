import React from "react";
import { useDropzone } from "react-dropzone";
import classNames from "classnames";
import { connect } from "react-redux";
import { Store } from "redux";
import { List, Message } from "semantic-ui-react";

import {
  putCurrentProjectDataSet,
  selectLoadDataSetError,
  selectLoadDataSetImagesLoaded,
  selectLoadDataSetImagesToLoad,
  selectLoadDataSetIsFetching,
} from "ducks/loadDataset";
import { ActionType, AddDatasetArguments, ErrorType, ProjectsInfoType } from "types/common";

import uniqueId from "lodash/uniqueId";

import styles from "./DatasetForm.module.scss";

interface Props {
  loadDataSetIsFetching: boolean;
  imagesToLoad: number | null;
  imagesLoaded: number;
  error: ErrorType[];
  currentProject: ProjectsInfoType | null;
  putCurrentProjectDataSet: (payload: AddDatasetArguments) => ActionType;
}

function DatasetForm(props: Props) {
  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: (files: any) =>
      props.putCurrentProjectDataSet({
        project_id: props.currentProject?._id!,
        images: files,
      }),
  });

  const { loadDataSetIsFetching, imagesLoaded, imagesToLoad, error } = props;
  return (
    <>
      {loadDataSetIsFetching && (
        <Message info={true}>
          Load {imagesLoaded} of {imagesToLoad}
        </Message>
      )}
      {!!error.length && (
        <Message negative={true}>
          Failed to load this images:
          <List bulleted={true}>
            {error.map((item) => (
              <List.Item key={uniqueId()}>{item.message}</List.Item>
            ))}
          </List>
        </Message>
      )}
      <div
        {...getRootProps()}
        className={classNames(styles.root, {
          [styles.dragAccept]: isDragAccept,
          [styles.dragReject]: isDragReject,
        })}
      >
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
    </>
  );
}

const mapStateToProps = (state: Store) => ({
  loadDataSetIsFetching: selectLoadDataSetIsFetching(state),
  imagesToLoad: selectLoadDataSetImagesToLoad(state),
  imagesLoaded: selectLoadDataSetImagesLoaded(state),
  error: selectLoadDataSetError(state),
});

const mapDispatchToProps = {
  putCurrentProjectDataSet,
};

export default connect(mapStateToProps, mapDispatchToProps)(DatasetForm);
