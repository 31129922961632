import React from "react";
import { Button, Form, Input, Message } from "semantic-ui-react";
import { reduxForm, Field, InjectedFormProps } from "redux-form";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import { Store } from "redux";

import SemanticFormField from "components/SemanticFormField/SemanticFormField";
import {
  containsCharacter,
  containsLowerCase,
  containsNumeric,
  containsUppercase,
  isEmail,
  maxLength256,
  maxLength99,
  minLength6,
  requiredEmail,
  requiredPassword,
} from "utils/validation";
import { FORMS, ROUTES } from "utils/constants";
import { ActionType, FormSubmitActionPayloadType } from "types/common";
import { formSubmitSignIn, selectAuthIsFetching } from "ducks/authorization";

import styles from "./SignInForm.module.scss";

interface OwnFormProps {
  authIsFetching: boolean;
  formSubmitSignIn: (payload: FormSubmitActionPayloadType<FormData>) => ActionType;
}

export interface FormData {
  email?: string;
  password?: string;
}

interface ComponentProps extends RouteComponentProps, OwnFormProps, InjectedFormProps<FormData, OwnFormProps> {}

function SignInForm(props: ComponentProps) {
  const { handleSubmit, authIsFetching, error } = props;

  const onSubmit = (formData: FormData) => {
    return new Promise((resolve, reject) =>
      props.formSubmitSignIn({
        formData,
        reject,
        resolve,
      })
    ).then(() => props.history.push(ROUTES.projects));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className={styles.root}>
      <Form.Field>
        <label>Email</label>
        <Field
          name="email"
          component={SemanticFormField}
          as={Input}
          type="text"
          placeholder="name@example.com"
          validate={[requiredEmail, isEmail, maxLength256]}
        />
      </Form.Field>
      <Form.Field>
        <label>Password</label>
        <Field
          name="password"
          component={SemanticFormField}
          as={Input}
          type="password"
          placeholder="Password"
          validate={[
            requiredPassword,
            containsUppercase,
            containsLowerCase,
            containsNumeric,
            containsCharacter,
            minLength6,
            maxLength99,
          ]}
        />
      </Form.Field>
      {!!error && <Message negative={true}>{error}</Message>}
      <div className={styles.submitContainer}>
        <Button type="submit" primary={true} loading={authIsFetching}>
          Submit
        </Button>
      </div>
    </Form>
  );
}

const reduxFormConfig = {
  form: FORMS.SIGN_IN_FORM,
  destroyOnUnmount: false,
};

const FormSignIn = reduxForm<FormData, OwnFormProps>(reduxFormConfig)(withRouter(SignInForm));

const mapStateToProps = (state: Store) => ({
  authIsFetching: selectAuthIsFetching(state),
});

const mapDispatchToProps = {
  formSubmitSignIn,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormSignIn);
