import React, { Component } from "react";
import { Button, Dropdown, Table } from "semantic-ui-react";
import { DropdownProps } from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";

import { CurrentUserType } from "types/common";
import { ACCESS_LEVELS } from "utils/constants";

interface Props {
  user: CurrentUserType;
  deleteUser: () => void;
  updateUser: (role: string) => void;
}

interface State {
  access: string;
}

class ProjectUsersListItem extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      access: this.props.user.access[0], //TODO: Backend must replace user.access from string[] to just string
    };
  }

  public updateUser = () => {
    this.props.updateUser(this.state.access);
  };

  public onAccessChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) =>
    this.setState({ access: data.value as string });

  public render() {
    const { user } = this.props;
    return (
      <Table.Row key={user.user_id}>
        <Table.Cell>{user.email}</Table.Cell>
        {/*TODO: Backend must replace user.access from string[] to just string*/}
        <Table.Cell>
          <Dropdown selection={true} options={ACCESS_LEVELS} value={this.state.access} onChange={this.onAccessChange} />
        </Table.Cell>
        <Table.Cell>
          <Button negative={true} onClick={this.props.deleteUser}>
            Delete
          </Button>
          <Button primary={true} disabled={this.state.access === this.props.user.access[0]} onClick={this.updateUser}>
            Update
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default ProjectUsersListItem;
