import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { ROUTES } from "utils/constants";
import Header from "components/Header/Header";
import ProtectedRoute from "components/PrivateRoute/PrivateRoute";
import Auth from "routes/Auth/Auth";
import Projects from "routes/Projects/Projects";
import CreateProject from "routes/CreateProject/CreateProject";
import ProjectInfo from "routes/ProjectInfo/ProjectInfo";
import Labeling from "routes/Labeling/Labeling";
import Reviewing from "routes/Reviewing/Reviewing";

import styles from "./RootRouter.module.scss";

class RootRouter extends Component {
  render() {
    return (
      <>
        <Header />
        <div className={styles.root}>
          <Switch>
            <Redirect from={ROUTES.root} exact={true} to={ROUTES.projects} />
            <Route path={ROUTES.authorization} exact={true} component={Auth} />
            <ProtectedRoute path={ROUTES.projects} exact={true} component={Projects} />
            <ProtectedRoute path={ROUTES.createProject} exact={true} component={CreateProject} />
            <ProtectedRoute path={ROUTES.projectInfo} exact={true} component={ProjectInfo} />
            <ProtectedRoute path={ROUTES.labeling} exact={true} component={Labeling} />
            <ProtectedRoute path={ROUTES.reviewing} exact={true} component={Reviewing} />
          </Switch>
        </div>
      </>
    );
  }
}

export default RootRouter;
