import { SagaIterator } from "redux-saga";
import { spawn } from "redux-saga/effects";

import { saga as authorization } from "ducks/authorization";
import { saga as projects } from "ducks/projects";
import { saga as currentProject } from "ducks/currentProject";
import { saga as dataset } from "ducks/dataset";
import { saga as loadDataset } from "ducks/loadDataset";
import { saga as labeling } from "ducks/labeling";
import { saga as classifiers } from "ducks/classifiers";
import { saga as labeledData } from "ducks/labeledData";
import { saga as users } from "ducks/users";
import { saga as currentProjectUsers } from "ducks/currentProjectUsers";

export default function* rootSaga(): SagaIterator {
  yield spawn(authorization);
  yield spawn(projects);
  yield spawn(currentProject);
  yield spawn(dataset);
  yield spawn(loadDataset);
  yield spawn(labeling);
  yield spawn(classifiers);
  yield spawn(labeledData);
  yield spawn(users);
  yield spawn(currentProjectUsers);
}
