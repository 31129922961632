import React, { Component } from "react";
import { Store } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";

import { fetchCurrentProject, selectCurrentIsProjectFetching, selectCurrentProject } from "ducks/currentProject";
import { ActionType, DataSetType, ProjectsInfoType } from "types/common";
import { fetchCurrentProjectDataSet, selectCurrentDataSet, selectCurrentDataSetIsFetching } from "ducks/dataset";
import FormLabeling from "components/LabelingForm/LabelingForm";
import ImageContainer from "components/ImageContainer/ImageContainer";
import { selectIsLabelingFetching } from "ducks/labeling";
import { FORMS } from "utils/constants";

import styles from "./Labeling.module.scss";

interface Props extends RouteComponentProps<{ id: string }> {
  currentIsProjectFetching: boolean;
  currentDataSetIsFetching: boolean;
  labelingIsFetching: boolean;
  currentProject: ProjectsInfoType | null;
  currentDataSet: DataSetType[] | null;
  fetchCurrentProject: (id: string) => ActionType;
  fetchCurrentProjectDataSet: (id: string) => ActionType;
}

interface State {
  dataset: DataSetType[] | null;
  activeIndex: number;
}

class Labeling extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      dataset: this.props.currentDataSet,
      activeIndex: 0,
    };
  }

  public componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchCurrentProject(id);
    this.props.fetchCurrentProjectDataSet(id);
  }

  public switchImage = (to: number) => {
    this.setState({ activeIndex: this.state.activeIndex + to });
  };

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>) {
    if (prevProps.currentDataSet !== this.props.currentDataSet) {
      this.setState({ dataset: this.props.currentDataSet });
    } else if (!this.props.currentDataSet || !this.props.currentDataSet[this.state.activeIndex]) {
      const { id } = this.props.match.params;
      this.props.history.push("/project/" + id);
    }
  }

  render() {
    const {
      currentProject,
      currentIsProjectFetching,
      currentDataSetIsFetching,
      labelingIsFetching,
      currentDataSet,
    } = this.props;
    const { activeIndex } = this.state;

    return (
      <div className={styles.root}>
        <Dimmer active={currentIsProjectFetching || currentDataSetIsFetching || labelingIsFetching}>
          <Loader />
        </Dimmer>
        <div className={styles.inputsWrapper}>
          {currentProject?.classifiers && currentDataSet?.length && currentDataSet[activeIndex] && (
            <FormLabeling
              classifiers={currentProject?.classifiers}
              imageData={currentDataSet[activeIndex]}
              onLabeling={this.switchImage}
              key={currentDataSet[activeIndex]._id}
              activeIndex={this.state.activeIndex}
              formId={FORMS.LABELING_FORM + "_" + this.state.activeIndex}
            />
          )}
        </div>
        <div className={styles.photoWrapper}>
          {currentDataSet?.length && currentDataSet[activeIndex] && (
            <ImageContainer
              imageData={currentDataSet[activeIndex]}
              key={currentDataSet[activeIndex]._id}
              switchImage={this.switchImage}
              activeIndex={activeIndex}
              currentDataSet={currentDataSet}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: Store) => ({
  currentIsProjectFetching: selectCurrentIsProjectFetching(state),
  currentDataSetIsFetching: selectCurrentDataSetIsFetching(state),
  labelingIsFetching: selectIsLabelingFetching(state),
  currentProject: selectCurrentProject(state),
  currentDataSet: selectCurrentDataSet(state),
});

const mapDispatchToProps = {
  fetchCurrentProject,
  fetchCurrentProjectDataSet,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Labeling));
