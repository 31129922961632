import { API_URI, appName } from "utils/constants";
import { ActionType, DeleteClassifiersArguments, ErrorType, FormSubmitActionPayloadType } from "types/common";
import { Store } from "redux";
import { createSelector } from "reselect";
import { NormalizedFormData as ClassifiersFormData } from "components/CreateClassifierForm/CreateClassifierForm";
import { SagaIterator } from "redux-saga";
import { all, call, put, select, take, takeEvery } from "redux-saga/effects";
import { SubmissionError } from "redux-form";
import { callApi } from "redux/api";
import { fetchCurrentProject, selectCurrentProject } from "ducks/currentProject";

/* Constants */

export const moduleName = "classifiers";

const prefix = `${appName}/${moduleName}`;

export const FORM_SUBMIT_CLASSIFIERS = `${prefix}/FORM_SUBMIT_CLASSIFIERS`;
export const FETCH_CLASSIFIERS_REQUEST = `${prefix}/FETCH_CLASSIFIERS_REQUEST`;
export const FETCH_CLASSIFIERS_SUCCESS = `${prefix}/FETCH_CLASSIFIERS_SUCCESS`;
export const FETCH_CLASSIFIERS_FAILURE = `${prefix}/FETCH_CLASSIFIERS_FAILURE`;

export const DELETE_CLASSIFIERS_REQUEST = `${prefix}/DELETE_CLASSIFIERS_REQUEST`;
export const DELETE_CLASSIFIERS_SUCCESS = `${prefix}/DELETE_CLASSIFIERS_SUCCESS`;
export const DELETE_CLASSIFIERS_FAILURE = `${prefix}/DELETE_CLASSIFIERS_FAILURE`;

/* Reducer */

interface State {
  error: ErrorType | null;
  isFetching: boolean;
}

const initialState: State = {
  error: null,
  isFetching: false,
};

export default function reducer(state: State = initialState, action: ActionType) {
  const { type, error } = action;

  switch (type) {
    case FORM_SUBMIT_CLASSIFIERS:
    case FETCH_CLASSIFIERS_REQUEST:
    case DELETE_CLASSIFIERS_REQUEST:
      return Object.assign({}, state, {
        error: null,
        isFetching: true,
      });

    case FETCH_CLASSIFIERS_SUCCESS:
    case DELETE_CLASSIFIERS_SUCCESS:
      return Object.assign({}, state, {
        error: null,
        isFetching: false,
      });

    case FETCH_CLASSIFIERS_FAILURE:
    case DELETE_CLASSIFIERS_FAILURE:
      return Object.assign({}, state, {
        error,
        isFetching: false,
      });

    default:
      return state;
  }
}

/*Selectors */

const stateSelector = (state: Store) => state[moduleName];

export const selectClassifiersIsFetching = createSelector(stateSelector, (state: State) => state.isFetching);

export const selectClassifiersError = createSelector(stateSelector, (state: State) => state.error);

/* Actions */

export function formSubmitClassifiers(payload: FormSubmitActionPayloadType<ClassifiersFormData>) {
  return {
    payload,
    type: FORM_SUBMIT_CLASSIFIERS,
  };
}

export function fetchClassifiers(payload: ClassifiersFormData) {
  return {
    payload,
    type: FETCH_CLASSIFIERS_REQUEST,
  };
}
export function fetchClassifiersSuccess() {
  return {
    type: FETCH_CLASSIFIERS_SUCCESS,
  };
}
export function fetchClassifiersFailure(error: ErrorType) {
  return {
    error,
    type: FETCH_CLASSIFIERS_FAILURE,
  };
}

export function deleteClassifiers(payload: DeleteClassifiersArguments) {
  return {
    payload,
    type: DELETE_CLASSIFIERS_REQUEST,
  };
}
export function deleteClassifiersSuccess() {
  return {
    type: DELETE_CLASSIFIERS_SUCCESS,
  };
}
export function deleteClassifiersFailure(error: ErrorType) {
  return {
    error,
    type: DELETE_CLASSIFIERS_FAILURE,
  };
}

/* Sagas */

function* formSubmitClassifiersSaga({ payload }: ActionType): SagaIterator {
  const { resolve, reject, formData } = payload;
  yield put(fetchClassifiers(formData));

  const fetchClassifiersResult = yield take([FETCH_CLASSIFIERS_SUCCESS, FETCH_CLASSIFIERS_FAILURE]);

  if (fetchClassifiersResult.type === FETCH_CLASSIFIERS_SUCCESS) {
    yield call(resolve);
  } else if (fetchClassifiersResult.type === FETCH_CLASSIFIERS_FAILURE) {
    const error = yield select(selectClassifiersError);

    yield call(reject, new SubmissionError({ _error: error.message || error }));
  }
}

function* fetchClassifiersSaga({ payload }: ActionType): SagaIterator {
  try {
    yield call(ApiFetchClassifiers, payload, payload.classifierToEdit);
    yield put(fetchClassifiersSuccess());
  } catch (error) {
    yield put(fetchClassifiersFailure(error));
  }
}

function* deleteClassifiersSaga({ payload }: ActionType): SagaIterator {
  try {
    yield call(ApiDeleteClassifiers, payload);
    yield put(deleteClassifiersSuccess());
    const currentProject = yield select(selectCurrentProject);
    yield put(fetchCurrentProject(currentProject._id));
  } catch (error) {
    yield put(deleteClassifiersFailure(error));
  }
}

export const saga = function* () {
  yield all([
    takeEvery(FORM_SUBMIT_CLASSIFIERS, formSubmitClassifiersSaga),
    takeEvery(FETCH_CLASSIFIERS_REQUEST, fetchClassifiersSaga),
    takeEvery(DELETE_CLASSIFIERS_REQUEST, deleteClassifiersSaga),
  ]);
};

/* API */

function* ApiFetchClassifiers(data: ClassifiersFormData, classifierToEdit: string | null) {
  delete data.classifierToEdit;
  return yield call(
    callApi,
    API_URI + `classifiers/${classifierToEdit || ""}`,
    data,
    classifierToEdit ? "PUT" : "POST"
  );
}

function* ApiDeleteClassifiers(data: DeleteClassifiersArguments) {
  return yield call(callApi, API_URI + "classifiers/", data, "DELETE");
}
