export const appName = "analytic";
export const API_URI = process.env.REACT_APP_API_URL;

export const ROUTES = {
  root: "/",
  authorization: "/auth",
  projects: "/projects",
  createProject: "/createProject",
  projectInfo: "/project/:id",
  labeling: "/labeling/:id",
  reviewing: "/reviewing/:id/:status",
};

export const FORMS = {
  SIGN_IN_FORM: "SIGN_IN_FORM",
  CREATE_PROJECT_FORM: "CREATE_PROJECT_FORM",
  LABELING_FORM: "LABELING_FORM",
  REVIEWING_FORM: "REVIEWING_FORM",
  CREATE_CLASSIFIER_FORM: "CREATE_CLASSIFIER_FORM",
};

export const ACCESS_LEVELS = [
  {
    value: "admin",
    text: "Admin",
  },
  {
    value: "editor",
    text: "Editor",
  },
  {
    value: "labeler",
    text: "Labeler",
  },
  {
    value: "reviewer",
    text: "Reviewer",
  },
  {
    value: "approver",
    text: "Approver",
  },
  {
    value: "labeler&reviewer",
    text: "Labeler & Reviewer",
  },
];
