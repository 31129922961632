import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { InjectedFormProps, reduxForm, SubmissionError } from "redux-form";
import { Button, Form, Message } from "semantic-ui-react";
import { connect } from "react-redux";
import { Store } from "redux";

import {
  ActionType,
  CheckboxAndRadioNormalisedDataType,
  ClassificationsAnswerType,
  ClassificationsType,
  ClassifiersType,
  DataSetType,
  FormSubmitActionPayloadType,
  LocaleLabelingResultType,
} from "types/common";
import { formSubmitReviewing } from "ducks/labeling";
import DynamicInput from "components/DynamicInput/DynamicInput";
import { putLocaleReviewingResult, selectLabeledDataSet } from "ducks/dataset";
import { normalizeClassificationsToFormValues } from "utils/helpers";

import styles from "./ReviewingForm.module.scss";

interface OwnFormProps {
  formId: string;
  activeIndex: number;
  classifiers: ClassifiersType[];
  imageData: DataSetType;
  onLabeling: (to: number) => void;
  formSubmitReviewing: (payload: FormSubmitActionPayloadType<FormData>) => ActionType;
  putLocaleReviewingResult: (payload: LocaleLabelingResultType<FormData>) => ActionType;
}

export interface FormData {
  [key: string]: any;
  imageId: string;
  status: string;
  classifications?: ClassificationsType[];
}

interface ComponentProps extends RouteComponentProps, OwnFormProps, InjectedFormProps<FormData, OwnFormProps> {}

function ReviewingForm(props: ComponentProps) {
  const { handleSubmit } = props;

  const normalizeCheckBoxValues = (formData: FormData): CheckboxAndRadioNormalisedDataType => {
    const checkboxValues = [];
    const normalizedCheckboxValues = {};
    for (const field in formData) {
      if (formData[field].type === "checkbox") {
        checkboxValues.push(formData[field]);
      }
    }
    checkboxValues.forEach((item) => {
      if (!!normalizedCheckboxValues[item.classifierName]) {
        normalizedCheckboxValues[item.classifierName].push({ value: item.value, category: item.category });
      } else {
        normalizedCheckboxValues[item.classifierName] = [{ value: item.value, category: item.category }];
      }
    });

    return normalizedCheckboxValues || {};
  };

  const normalizeRadioValues = (formData: FormData): CheckboxAndRadioNormalisedDataType => {
    const normalizedRadioValues = {};
    for (const field in formData) {
      const fieldData = formData[field];
      if (fieldData.type === "radiobutton") {
        normalizedRadioValues[field] = {
          value: fieldData.value,
          category: fieldData.category,
        };
      }
    }

    return normalizedRadioValues || {};
  };

  const getDropdownValues = (formData: FormData): ClassificationsType[] => {
    const classifications: ClassificationsType[] = [];
    for (const field in formData) {
      const dropdownClassifier = props.classifiers.find((item) => item.name === field && item.type === "dropdown");
      if (!!dropdownClassifier) {
        classifications.push({
          classifier_id: dropdownClassifier._id,
          name: dropdownClassifier.name,
          answer: formData[field],
        });
      }
    }
    return classifications;
  };

  const getCheckboxValues = (formData: FormData): ClassificationsType[] => {
    const classifications: ClassificationsType[] = [];
    for (const field in formData) {
      const checkboxClassifier = formData[field].type === "checkbox" ? formData[field] : undefined;

      if (!!checkboxClassifier) {
        const index = classifications.findIndex((item) => item.classifier_id === checkboxClassifier.classifierId);
        if (index === -1) {
          classifications.push({
            classifier_id: checkboxClassifier.classifierId,
            name: checkboxClassifier.classifierName,
            answer: [
              {
                value: formData[field].value,
                category: formData[field].category,
              },
            ],
          });
        } else {
          (classifications[index].answer as ClassificationsAnswerType[]).push({
            value: formData[field].value,
            category: formData[field].category,
          });
        }
      }
    }

    return classifications;
  };

  const getRadioValues = (formData: FormData): ClassificationsType[] => {
    const classifications: ClassificationsType[] = [];
    for (const field in formData) {
      const radioClassifier = formData[field].type === "radiobutton" ? formData[field] : undefined;

      if (!!radioClassifier) {
        classifications.push({
          classifier_id: radioClassifier.classifierId,
          name: radioClassifier.classifierName,
          answer: [
            {
              value: radioClassifier.value,
              category: radioClassifier.category,
            },
          ],
        });
      }
    }
    return classifications;
  };

  const getErrors = (formData: FormData) => {
    const errors = {};
    props.classifiers
      .slice()
      .filter((item) => item.required)
      .forEach((item) => {
        if (item.type === "dropdown") {
          if (formData[item.name] === undefined) {
            errors[item.name] = "Required";
          }
        } else if (item.type === "checkbox") {
          const checkboxes = normalizeCheckBoxValues(formData);
          if (!checkboxes[item.name]) {
            //@ts-ignore
            errors._error = "Please choose a value for " + item.name;
          }
        } else if (item.type === "radiobutton") {
          const radiobuttons = normalizeRadioValues(formData);
          if (!radiobuttons[item.name]) {
            //@ts-ignore
            errors._error = "Please choose a value for " + item.name;
          }
        }
      });

    if (!!Object.keys(errors).length) {
      throw new SubmissionError(errors);
    }
  };

  const handleSubmitLabelingImage = (formData: FormData) => {
    getErrors(formData);
    const classifications: ClassificationsType[] = [
      ...getDropdownValues(formData),
      ...getCheckboxValues(formData),
      ...getRadioValues(formData),
    ];
    let status = window.location.href.split('/')[window.location.href.split('/').length - 1];
    //console.log('==================='+status+'=========================');
    return new Promise((resolve, reject) =>
 
      props.formSubmitReviewing({
        formData: {
          imageId: props.imageData._id,
          status: status,
          activeIndex: props.activeIndex,
          classifications,
        },
        reject,
        resolve,
      })
    ).then(() => {
      props.onLabeling(1);
    });
  };

  const handleRejectLabelingImage = () => {
    return new Promise((resolve, reject) =>
      props.formSubmitReviewing({
        formData: {
          imageId: props.imageData._id,
          status: "rejected",
        },
        reject,
        resolve,
      })
    ).then(() => {
      props.onLabeling(1);
    });
  };

  const preRenderInputs = () => {
    return props.classifiers.map((inputData) => {
      return <DynamicInput classifier={inputData} key={inputData._id} />;
    });
  };

  return (
    <Form onSubmit={handleSubmit(handleSubmitLabelingImage)} className={styles.root}>
      <div className={styles.submitsContainer}>
        <div className="ui button" onClick={handleSubmit(handleRejectLabelingImage)}>
          Reject
        </div>
        <Button type="submit" primary={true}>
          Approve
        </Button>
      </div>
      {props.error && (
        <div className={styles.globalError}>
          <Message visible={true} error={true}>
            {props.error}
          </Message>
        </div>
      )}
      <div className={styles.inputsContainer}>{preRenderInputs()}</div>
    </Form>
  );
}

const reduxFormConfig = {};

const FormReviewing = reduxForm<FormData, OwnFormProps>(reduxFormConfig)(withRouter(ReviewingForm));

const mapStateToProps = (state: Store, props: any) => {
  const dataSet = selectLabeledDataSet(state);
  const { activeIndex } = props;
  let initialValues = {};
  if (!!dataSet && !!dataSet[activeIndex].label?.classifications) {
    initialValues = normalizeClassificationsToFormValues(
      dataSet[activeIndex].label?.classifications!,
      props.classifiers
    );
  }
  return { initialValues, form: props.formId };
};

const mapDispatchToProps = {
  formSubmitReviewing,
  putLocaleReviewingResult,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormReviewing);
