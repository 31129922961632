import { Store } from "redux";
import { createSelector } from "reselect";
import { SagaIterator } from "redux-saga";
import { all, call, put, select, take, takeEvery } from "redux-saga/effects";
import { SubmissionError } from "redux-form";

import { API_URI, appName } from "utils/constants";
import { ActionType, DataSetType, ErrorType, FormSubmitActionPayloadType } from "types/common";
import { FormData as LabelingFormData } from "components/LabelingForm/LabelingForm";
import { callApi } from "redux/api";
import { putLocaleReviewingResult } from "ducks/dataset";

/* Constants */

export const moduleName = "labeling";

const prefix = `${appName}/${moduleName}`;

export const FORM_SUBMIT_LABELING = `${prefix}/FORM_SUBMIT_LABELING`;
export const FETCH_LABELING_REQUEST = `${prefix}/FETCH_LABELING_REQUEST`;
export const FETCH_LABELING_SUCCESS = `${prefix}/FETCH_LABELING_SUCCESS`;
export const FETCH_LABELING_FAILURE = `${prefix}/FETCH_LABELING_FAILURE`;

export const FORM_SUBMIT_REVIEWING = `${prefix}/FORM_SUBMIT_REVIEWING`;
export const FETCH_REVIEWING_REQUEST = `${prefix}/FETCH_REVIEWING_REQUEST`;
export const FETCH_REVIEWING_SUCCESS = `${prefix}/FETCH_REVIEWING_SUCCESS`;
export const FETCH_REVIEWING_FAILURE = `${prefix}/FETCH_REVIEWING_FAILURE`;

/* Reducer */

interface State {
  error: ErrorType | null;
  isFetching: boolean;
}

const initialState: State = {
  error: null,
  isFetching: false,
};

export default function reducer(state: State = initialState, action: ActionType) {
  const { type, error } = action;

  switch (type) {
    case FORM_SUBMIT_LABELING:
    case FETCH_LABELING_REQUEST:
    case FORM_SUBMIT_REVIEWING:
    case FETCH_REVIEWING_REQUEST:
      return Object.assign({}, state, {
        error: null,
        isFetching: true,
      });

    case FETCH_LABELING_SUCCESS:
    case FETCH_REVIEWING_SUCCESS:
      return Object.assign({}, state, {
        error: null,
        isFetching: false,
      });

    case FETCH_LABELING_FAILURE:
    case FETCH_REVIEWING_FAILURE:
      return Object.assign({}, state, {
        error,
        isFetching: false,
      });

    default:
      return state;
  }
}

/* Selectors */

const stateSelector = (state: Store) => state[moduleName];

export const selectIsLabelingFetching = createSelector(stateSelector, (state: State) => state.isFetching);

export const selectLabelingError = createSelector(stateSelector, (state: State) => state.error);

/* Actions */

export function formSubmitLabeling(payload: FormSubmitActionPayloadType<LabelingFormData>) {
  return {
    payload,
    type: FORM_SUBMIT_LABELING,
  };
}
export function fetchLabeling(payload: LabelingFormData) {
  return {
    payload,
    type: FETCH_LABELING_REQUEST,
  };
}
export function fetchLabelingSuccess(payload: DataSetType) {
  return {
    payload,
    type: FETCH_LABELING_SUCCESS,
  };
}
export function fetchLabelingFailure(error: ErrorType) {
  return {
    error,
    type: FETCH_LABELING_FAILURE,
  };
}

export function formSubmitReviewing(payload: FormSubmitActionPayloadType<LabelingFormData>) {
  return {
    payload,
    type: FORM_SUBMIT_REVIEWING,
  };
}
export function fetchReviewing(payload: LabelingFormData) {
  return {
    payload,
    type: FETCH_REVIEWING_REQUEST,
  };
}
export function fetchReviewingSuccess(payload: DataSetType) {
  return {
    payload,
    type: FETCH_REVIEWING_SUCCESS,
  };
}
export function fetchReviewingFailure(error: ErrorType) {
  return {
    error,
    type: FETCH_REVIEWING_FAILURE,
  };
}

/* Sagas */

function* formSubmitLabelingSaga({ payload }: ActionType): SagaIterator {
  const { resolve, reject, formData } = payload;
  yield put(fetchLabeling(formData));

  const fetchLabelingResult = yield take([FETCH_LABELING_SUCCESS, FETCH_LABELING_FAILURE]);

  if (fetchLabelingResult.type === FETCH_LABELING_SUCCESS) {
    yield call(resolve);
  } else if (fetchLabelingResult.type === FETCH_LABELING_FAILURE) {
    const error = yield select(selectLabelingError);

    yield call(reject, new SubmissionError({ _error: error.message || error }));
  }
}

function* fetchLabelingSaga({ payload }: ActionType): SagaIterator {
  try {
    const data = {
      status: payload.status,
    };
    if (payload.classifications) {
      Object.assign(data, {
        classifications: payload.classifications,
      });
    }
    const result = yield call(ApiFetchLabeling, payload.imageId, data);
    yield put(fetchLabelingSuccess(result));
  } catch (error) {
    yield put(fetchLabelingFailure(error));
  }
}

function* formSubmitReviewingSaga({ payload }: ActionType): SagaIterator {
  const { resolve, reject, formData } = payload;
  yield put(fetchReviewing(formData));

  const fetchReviewingResult = yield take([FETCH_REVIEWING_SUCCESS, FETCH_REVIEWING_FAILURE]);

  if (fetchReviewingResult.type === FETCH_REVIEWING_SUCCESS) {
    yield call(resolve);
  } else if (fetchReviewingResult.type === FETCH_REVIEWING_FAILURE) {
    const error = yield select(selectLabelingError);

    yield call(reject, new SubmissionError({ _error: error.message || error }));
  }
}

function* fetchReviewingSaga({ payload }: ActionType): SagaIterator {
  try {
    const data = {
      status: payload.status,
    };
    if (payload.classifications) {
      Object.assign(data, {
        classifications: payload.classifications,
      });
    }
    const result = yield call(ApiFetchLabeling, payload.imageId, data);
    yield put(fetchReviewingSuccess(result));

    yield put(
      putLocaleReviewingResult({
        formData: payload.classifications,
        index: payload.activeIndex,
      })
    );
  } catch (error) {
    yield put(fetchReviewingFailure(error));
  }
}

export const saga = function* () {
  yield all([
    takeEvery(FORM_SUBMIT_LABELING, formSubmitLabelingSaga),
    takeEvery(FETCH_LABELING_REQUEST, fetchLabelingSaga),
    takeEvery(FORM_SUBMIT_REVIEWING, formSubmitReviewingSaga),
    takeEvery(FETCH_REVIEWING_REQUEST, fetchReviewingSaga),
  ]);
};

/* API */

function* ApiFetchLabeling(imageId: string, data: { status: string }) {
  return yield call(callApi, API_URI + "labels/" + imageId + "/label-data", data, "POST");
}
