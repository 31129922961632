import { API_URI, appName, ROUTES } from "utils/constants";
import { ActionType, ErrorType, ProjectsInfoType } from "types/common";
import { Store } from "redux";
import { createSelector } from "reselect";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { callApi } from "redux/api";
import history from "redux/history";

/* Constants */

export const moduleName = "currentProject";

const prefix = `${appName}/${moduleName}`;

export const FETCH_PROJECT_INFO_REQUEST = `${prefix}/FETCH_PROJECT_INFO_REQUEST`;
export const FETCH_PROJECT_INFO_SUCCESS = `${prefix}/FETCH_PROJECT_INFO_SUCCESS`;
export const FETCH_PROJECT_INFO_FAILURE = `${prefix}/FETCH_PROJECT_INFO_FAILURE`;

export const DELETE_PROJECT_REQUEST = `${prefix}/DELETE_PROJECT_REQUEST`;
export const DELETE_PROJECT_SUCCESS = `${prefix}/DELETE_PROJECT_SUCCESS`;
export const DELETE_PROJECT_FAILURE = `${prefix}/DELETE_PROJECT_FAILURE`;

/* Reducer */

interface State {
  error: ErrorType | null;
  isFetching: boolean;
  entities: ProjectsInfoType | null;
}

const initialState: State = {
  error: null,
  isFetching: false,
  entities: null,
};

export default function reducer(state: State = initialState, action: ActionType) {
  const { type, payload, error } = action;

  switch (type) {
    case FETCH_PROJECT_INFO_REQUEST:
    case DELETE_PROJECT_REQUEST:
      return Object.assign({}, state, {
        error: null,
        isFetching: true,
      });

    case FETCH_PROJECT_INFO_SUCCESS:
      return Object.assign({}, state, {
        entities: payload,
        error: null,
        isFetching: false,
      });

    case DELETE_PROJECT_SUCCESS:
      return Object.assign({}, state, {
        entities: null,
        error: null,
        isFetching: false,
      });

    case FETCH_PROJECT_INFO_FAILURE:
    case DELETE_PROJECT_FAILURE:
      return Object.assign({}, state, {
        error,
        isFetching: false,
      });

    default:
      return state;
  }
}

/*Selectors */

const stateSelector = (state: Store) => state[moduleName];

export const selectCurrentProject = createSelector(stateSelector, (state: State) => state.entities);

export const selectCurrentIsProjectFetching = createSelector(stateSelector, (state: State) => state.isFetching);

export const selectCurrentProjectError = createSelector(stateSelector, (state: State) => state.error);

/* Actions */

export function fetchCurrentProject(id: string) {
  return {
    payload: id,
    type: FETCH_PROJECT_INFO_REQUEST,
  };
}
export function fetchCurrentProjectSuccess(payload: ProjectsInfoType) {
  return {
    payload,
    type: FETCH_PROJECT_INFO_SUCCESS,
  };
}
export function fetchCurrentProjectFailure(error: ErrorType) {
  return {
    error,
    type: FETCH_PROJECT_INFO_FAILURE,
  };
}

export function deleteCurrentProject(id: string) {
  return {
    payload: id,
    type: DELETE_PROJECT_REQUEST,
  };
}
export function deleteCurrentProjectSuccess() {
  return {
    type: DELETE_PROJECT_SUCCESS,
  };
}
export function deleteCurrentProjectFailure(error: ErrorType) {
  return {
    error,
    type: DELETE_PROJECT_FAILURE,
  };
}

/* Sagas */

function* fetchCurrentProjectSaga({ payload }: ActionType): SagaIterator {
  try {
    const response = yield call(ApiFetchCurrentProject, payload);
    yield put(fetchCurrentProjectSuccess(response));
  } catch (error) {
    yield put(fetchCurrentProjectFailure(error));
  }
}

function* deleteCurrentProjectSaga({ payload }: ActionType): SagaIterator {
  try {
    yield call(ApiDeleteCurrentProject, payload);
    yield put(deleteCurrentProjectSuccess());
    history.push(ROUTES.projects);
  } catch (error) {
    yield put(deleteCurrentProjectFailure(error));
  }
}

export const saga = function* () {
  yield all([
    takeEvery(FETCH_PROJECT_INFO_REQUEST, fetchCurrentProjectSaga),
    takeEvery(DELETE_PROJECT_REQUEST, deleteCurrentProjectSaga),
  ]);
};

/* API */

function* ApiFetchCurrentProject(id: string) {
  return yield call(callApi, API_URI + "projects/" + id);
}
function* ApiDeleteCurrentProject(id: string) {
  return yield call(callApi, API_URI + "projects/" + id, undefined, "DELETE");
}
