import React from "react";
import { Store } from "redux";
import { connect } from "react-redux";

import { selectCurrentProject } from "ducks/currentProject";
import { ProjectsInfoType } from "types/common";

interface Props {
  rolesToAccess: string[];
  currentProject: ProjectsInfoType | null;
  children?: React.ReactNode;
}

function PrivateComponent(props: Props) {
  const currentAccess = props.currentProject?.access;
  const isAccessGranted = props.rolesToAccess.find((item) => item === currentAccess)?.length;
  return <>{isAccessGranted ? props.children : null}</>;
}

const mapStateToProps = (state: Store) => ({
  currentProject: selectCurrentProject(state),
});

export default connect(mapStateToProps)(PrivateComponent);
