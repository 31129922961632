import { Store } from "redux";
import { createSelector } from "reselect";
import { all, call, put, select, take, takeEvery } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";

import { API_URI, appName } from "utils/constants";
import { ActionType, ErrorType, FormSubmitActionPayloadType, ProjectsType } from "types/common";
import { callApi } from "redux/api";
import { FormData as CreateProjectFormData } from "components/CreateProjectForm/CreateProjectForm";
import { SubmissionError } from "redux-form";

/* Constants */

export const moduleName = "projects";

const prefix = `${appName}/${moduleName}`;

export const FETCH_PROJECTS_REQUEST = `${prefix}/FETCH_PROJECTS_REQUEST`;
export const FETCH_PROJECTS_SUCCESS = `${prefix}/FETCH_PROJECTS_SUCCESS`;
export const FETCH_PROJECTS_FAILURE = `${prefix}/FETCH_PROJECTS_FAILURE`;

export const FORM_SUBMIT_CREATE_PROJECT = `${prefix}/FORM_SUBMIT_CREATE_PROJECT`;
export const FETCH_CREATE_PROJECT_REQUEST = `${prefix}/FETCH_CREATE_PROJECT_REQUEST`;
export const FETCH_CREATE_PROJECT_SUCCESS = `${prefix}/FETCH_CREATE_PROJECT_SUCCESS`;
export const FETCH_CREATE_PROJECT_FAILURE = `${prefix}/FETCH_CREATE_PROJECT_FAILURE`;

/* Reducer */

interface State {
  error: ErrorType | null;
  isFetching: boolean;
  entities: ProjectsType[] | null;
}

const initialState: State = {
  error: null,
  isFetching: false,
  entities: null,
};

export default function reducer(state: State = initialState, action: ActionType) {
  const { type, payload, error } = action;

  switch (type) {
    case FETCH_PROJECTS_REQUEST:
    case FORM_SUBMIT_CREATE_PROJECT:
    case FETCH_CREATE_PROJECT_REQUEST:
      return Object.assign({}, state, {
        error: null,
        isFetching: true,
      });

    case FETCH_PROJECTS_SUCCESS:
      return Object.assign({}, state, {
        entities: payload,
        error: null,
        isFetching: false,
      });

    case FETCH_CREATE_PROJECT_SUCCESS:
      return Object.assign({}, state, {
        error: null,
        isFetching: false,
      });

    case FETCH_PROJECTS_FAILURE:
    case FETCH_CREATE_PROJECT_FAILURE:
      return Object.assign({}, state, {
        error,
        isFetching: false,
      });

    default:
      return state;
  }
}

/* Selectors */

const stateSelector = (state: Store) => state[moduleName];

export const selectProjects = createSelector(stateSelector, (state: State) => state.entities);

export const selectIsProjectsFetching = createSelector(stateSelector, (state: State) => state.isFetching);

export const selectProjectsError = createSelector(stateSelector, (state: State) => state.error);

/* Actions */

export function fetchProjects() {
  return {
    type: FETCH_PROJECTS_REQUEST,
  };
}
export function fetchProjectsSuccess(payload: ProjectsType[]) {
  return {
    payload,
    type: FETCH_PROJECTS_SUCCESS,
  };
}
export function fetchProjectsFailure(error: ErrorType) {
  return {
    error,
    type: FETCH_PROJECTS_FAILURE,
  };
}

export function formSubmitCreateProject(payload: FormSubmitActionPayloadType<CreateProjectFormData>) {
  return {
    payload,
    type: FORM_SUBMIT_CREATE_PROJECT,
  };
}
export function fetchCreateProject(payload: CreateProjectFormData) {
  return {
    payload,
    type: FETCH_CREATE_PROJECT_REQUEST,
  };
}
export function fetchCreateProjectSuccess(payload: any) {
  return {
    payload,
    type: FETCH_CREATE_PROJECT_SUCCESS,
  };
}
export function fetchCreateProjectError(error: ErrorType) {
  return {
    error,
    type: FETCH_CREATE_PROJECT_FAILURE,
  };
}

/* Sagas */

function* fetchProjectsSaga(): SagaIterator {
  try {
    const response = yield call(ApiFetchProjects);
    yield put(fetchProjectsSuccess(response));
  } catch (error) {
    yield put(fetchProjectsFailure(error));
  }
}

function* formSubmitCreateProjectSaga({ payload }: ActionType): SagaIterator {
  const { resolve, reject, formData } = payload;
  yield put(fetchCreateProject(formData));

  const fetchCreateProjectResult = yield take([FETCH_CREATE_PROJECT_SUCCESS, FETCH_CREATE_PROJECT_FAILURE]);

  if (fetchCreateProjectResult.type === FETCH_CREATE_PROJECT_SUCCESS) {
    yield call(resolve);
  } else if (fetchCreateProjectResult.type === FETCH_CREATE_PROJECT_FAILURE) {
    const error = yield select(selectProjectsError);

    yield call(reject, new SubmissionError({ _error: error.message || error }));
  }
}

function* fetchCreateProjectSaga({ payload }: ActionType): SagaIterator {
  try {
    const { name } = payload;
    const result = yield call(ApiFetchCreateProjects, name);
    yield put(fetchCreateProjectSuccess(result));
  } catch (error) {
    yield put(fetchCreateProjectError(error));
  }
}

export const saga = function* () {
  yield all([
    takeEvery(FETCH_PROJECTS_REQUEST, fetchProjectsSaga),
    takeEvery(FORM_SUBMIT_CREATE_PROJECT, formSubmitCreateProjectSaga),
    takeEvery(FETCH_CREATE_PROJECT_REQUEST, fetchCreateProjectSaga),
  ]);
};

/* API */

function* ApiFetchProjects() {
  return yield call(callApi, API_URI + "projects");
}

function* ApiFetchCreateProjects(name: string) {
  return yield call(callApi, API_URI + "projects", { name }, "POST");
}
