import { API_URI, appName } from "utils/constants";
import { ActionType, AddDatasetArguments, AddDatasetImageArguments, ErrorType } from "types/common";
import { Store } from "redux";
import { createSelector } from "reselect";
import { SagaIterator } from "redux-saga";
import { all, call, put, select, take, takeEvery } from "redux-saga/effects";
import { callApi } from "redux/api";

/* Constants */

export const moduleName = "loadDataset";

const prefix = `${appName}/${moduleName}`;

export const PUT_PROJECT_DATASET_REQUEST = `${prefix}/PUT_PROJECT_DATASET_REQUEST`;
export const PUT_PROJECT_DATASET_SUCCESS = `${prefix}/PUT_PROJECT_DATASET_SUCCESS`;
export const PUT_PROJECT_DATASET_FAILURE = `${prefix}/PUT_PROJECT_DATASET_FAILURE`;

export const PUT_PROJECT_DATASET_IMAGE_REQUEST = `${prefix}/PUT_PROJECT_DATASET_IMAGE_REQUEST`;
export const PUT_PROJECT_DATASET_IMAGE_SUCCESS = `${prefix}/PUT_PROJECT_DATASET_IMAGE_SUCCESS`;
export const PUT_PROJECT_DATASET_IMAGE_FAILURE = `${prefix}/PUT_PROJECT_DATASET_IMAGE_FAILURE`;

/* Reducer */

interface State {
  error: ErrorType[];
  isFetching: boolean;
  imagesToLoad: number | null;
  loadedImages: number;
}

const initialState: State = {
  error: [],
  isFetching: false,
  imagesToLoad: null,
  loadedImages: 1,
};

export default function reducer(state: State = initialState, action: ActionType) {
  const { type, payload, error } = action;

  switch (type) {
    case PUT_PROJECT_DATASET_REQUEST:
      return Object.assign({}, state, {
        error: [],
        isFetching: true,
        imagesToLoad: payload.images.length,
      });

    case PUT_PROJECT_DATASET_SUCCESS:
      return Object.assign({}, state, {
        error: [],
        isFetching: false,
        loadedImages: 1,
        imagesToLoad: null,
      });

    case PUT_PROJECT_DATASET_IMAGE_SUCCESS:
      return Object.assign({}, state, {
        error: [],
        loadedImages: state.loadedImages + 1,
      });

    case PUT_PROJECT_DATASET_IMAGE_FAILURE:
      return Object.assign({}, state, {
        error: [...state.error, error],
        loadedImages: state.loadedImages + 1,
      });

    case PUT_PROJECT_DATASET_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        loadedImages: 1,
        imagesToLoad: null,
      });

    default:
      return state;
  }
}

/* Selectors */

const stateSelector = (state: Store) => state[moduleName];

export const selectLoadDataSetImagesToLoad = createSelector(stateSelector, (state: State) => state.imagesToLoad);

export const selectLoadDataSetImagesLoaded = createSelector(stateSelector, (state: State) => state.loadedImages);

export const selectLoadDataSetIsFetching = createSelector(stateSelector, (state: State) => state.isFetching);

export const selectLoadDataSetError = createSelector(stateSelector, (state: State) => state.error);

/* Actions */

export function putCurrentProjectDataSet(payload: AddDatasetArguments) {
  return {
    payload,
    type: PUT_PROJECT_DATASET_REQUEST,
  };
}
export function putCurrentProjectDataSetSuccess() {
  return {
    type: PUT_PROJECT_DATASET_SUCCESS,
  };
}
export function putCurrentProjectDataSetFailure(error: ErrorType[]) {
  return {
    error,
    type: PUT_PROJECT_DATASET_FAILURE,
  };
}

export function putCurrentProjectDataSetImage(payload: AddDatasetImageArguments) {
  return {
    payload,
    type: PUT_PROJECT_DATASET_IMAGE_REQUEST,
  };
}
export function putCurrentProjectDataSetImageSuccess(payload: number) {
  return {
    payload,
    type: PUT_PROJECT_DATASET_IMAGE_SUCCESS,
  };
}
export function putCurrentProjectDataSetImageFailure(error: ErrorType) {
  return {
    error,
    type: PUT_PROJECT_DATASET_IMAGE_FAILURE,
  };
}

/* Sagas */

function* putCurrentProjectDataSetSaga({ payload }: ActionType): SagaIterator {
  try {
    for (let index = 0; index < payload.images.length; index++) {
      yield put(
        putCurrentProjectDataSetImage({
          project_id: payload.project_id,
          image: payload.images[index],
          index,
        })
      );
      yield take([PUT_PROJECT_DATASET_IMAGE_SUCCESS, PUT_PROJECT_DATASET_IMAGE_FAILURE]);
    }
    const errors = yield select(selectLoadDataSetError);
    if (errors.length) {
      yield put(putCurrentProjectDataSetFailure(errors));
      return;
    }
    yield put(putCurrentProjectDataSetSuccess());
  } catch (error) {
    yield put(putCurrentProjectDataSetFailure([error] as ErrorType[]));
  }
}

function* putCurrentProjectDataSetImageSaga({ payload }: ActionType): SagaIterator {
  try {
    const { index } = payload;
    const formData = new FormData();
    formData.append("project_id", payload.project_id);
    formData.append("images", payload.image, payload.name);

    yield call(ApiPutCurrentProjectDataSet, formData);
    yield put(putCurrentProjectDataSetImageSuccess(index));
  } catch (error) {
    yield put(putCurrentProjectDataSetImageFailure(new Error(payload.image.name)));
  }
}

export const saga = function* () {
  yield all([
    takeEvery(PUT_PROJECT_DATASET_REQUEST, putCurrentProjectDataSetSaga),
    takeEvery(PUT_PROJECT_DATASET_IMAGE_REQUEST, putCurrentProjectDataSetImageSaga),
  ]);
};

/* API */

function* ApiPutCurrentProjectDataSet(data: FormData) {
  return yield call(callApi, API_URI + "labels/upload-dataset", data, "POST");
}
