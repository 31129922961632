import React, { Component } from "react";
import { Button, Icon, Loader } from "semantic-ui-react";
import classNames from "classnames";

import { DataSetType } from "types/common";

import styles from "./ImageContainer.module.scss";

interface Props {
  imageData: DataSetType;
  currentDataSet: DataSetType[];
  switchImage: (to: number) => void;
  activeIndex: number;
}

interface State {
  isLoaded: boolean;
  currentZoom: number;
}

export default class ImageContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoaded: false,
      currentZoom: 1,
    };
  }

  public goNext = () => {
    this.props.switchImage(1);
  };

  public goPrevious = () => {
    this.props.switchImage(-1);
  };

  public zoom = (val: number) => () => {
    this.setState({ currentZoom: this.state.currentZoom + val });
  };

  public onImageLoadStart = () => {
    this.setState({ isLoaded: false });
  };

  public onImageLoad = () => {
    this.setState({ isLoaded: true });
  };

  render() {
    const { activeIndex, currentDataSet, imageData } = this.props;
    const { currentZoom } = this.state;

    return (
      <div className={styles.root} draggable="false">
        <div className={styles.controls}>
          <div className={styles.count}>
            Image {activeIndex + 1} of {currentDataSet.length}
          </div>
          <div className={styles.switchers}>
            <Button disabled={activeIndex === 0} onClick={this.goPrevious}>
              Previous
            </Button>
            <Button disabled={activeIndex === currentDataSet.length - 1} onClick={this.goNext}>
              Next
            </Button>
          </div>
          <div className={styles.zoom}>
            <Button disabled={currentZoom === 1} icon={true} onClick={this.zoom(-0.2)}>
              <Icon name="zoom-out" />
            </Button>
            <Button disabled={currentZoom >= 1.6} icon={true} onClick={this.zoom(0.2)}>
              <Icon name="zoom-in" />
            </Button>
          </div>
        </div>
        <div className={styles.photoWrapper}>
          <img
            key={imageData.file_url}
            className={classNames(styles.img, {
              [styles.active]: this.state.isLoaded,
            })}
            style={{ transform: `scale(${currentZoom})` }}
            src={imageData.file_url}
            alt="model"
            draggable="false"
            onLoadStart={this.onImageLoadStart}
            onLoad={this.onImageLoad}
          />
        </div>

        {!this.state.isLoaded && <Loader active={true} />}
      </div>
    );
  }
}
