import * as React from "react";
import { connect } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router";
import { Store } from "redux";

import { ROUTES } from "utils/constants";
import { selectAuthEntities } from "ducks/authorization";

export interface ProtectedRouteProps extends RouteProps {
  isAuthenticated: any;
}

class ProtectedRoute extends Route<ProtectedRouteProps> {
  public render() {
    if (!this.props.isAuthenticated?.signInUserSession) {
      const renderComponent = () => <Redirect to={{ pathname: ROUTES.authorization }} />;
      return <Route {...this.props} component={renderComponent} />;
    } else {
      return <Route {...this.props} />;
    }
  }
}

const mapStateToProps = (state: Store) => ({
  isAuthenticated: selectAuthEntities(state),
});

export default connect(mapStateToProps)(ProtectedRoute);
