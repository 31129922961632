import React, { Component, SyntheticEvent } from "react";
import { Store } from "redux";
import { connect } from "react-redux";
import { Divider, Input, Table } from "semantic-ui-react";
import { InputOnChangeData } from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import { selectCurrentProject } from "ducks/currentProject";

import { ActionType, AddUserToProjectArguments, ProjectsInfoType, UserType } from "types/common";
import { putUserToProject } from "ducks/currentProjectUsers";
import AddUserLitsItem from "components/AddUserLitsItem/AddUserLitsItem";

import styles from "components/ProjectsList/ProjectsList.module.scss";

interface Props {
  currentProject: ProjectsInfoType | null;
  users: UserType[];
  putUserToProject: (payload: AddUserToProjectArguments) => ActionType;
}

interface State {
  filteredUsers: UserType[];
}

class AddUserToProject extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      filteredUsers: this.props.users,
    };
  }

  public onSearchChange = (event: SyntheticEvent, data: InputOnChangeData) => {
    this.setState({
      filteredUsers: this.props.users
        .slice()
        .filter((item) => item.email.toLowerCase().indexOf(data.value.toLowerCase()) !== -1),
    });
  };

  public renderUsers = () =>
    this.state.filteredUsers.map((user: UserType) => {
      return (
        <AddUserLitsItem
          user={user}
          key={user.user_id}
          addUser={this.props.putUserToProject}
          currentProjectId={this.props.currentProject?._id!}
        />
      );
    });

  public render() {
    const { currentProject } = this.props;

    return (
      <div>
        <div className={styles.inputContainer}>
          <Input placeholder="Search..." fluid={true} onChange={this.onSearchChange} />
        </div>
        <Divider />
        <Table celled={true} padded={true}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Role</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{!!currentProject && this.renderUsers()}</Table.Body>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state: Store) => ({
  currentProject: selectCurrentProject(state),
});

const mapDispatchToProps = {
  putUserToProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserToProject);
