import React from "react";
import { FieldArrayFieldsProps, WrappedFieldArrayProps } from "redux-form/lib/FieldArray";
import { Button, Header, Input } from "semantic-ui-react";
import { Field, FieldArray } from "redux-form";
import SemanticFormField from "components/SemanticFormField/SemanticFormField";
import { requiredName } from "utils/validation";
import DropdownClassifierOptionsFieldGroup from "components/DropdownClassifierOptionsFieldGroup/DropdownClassifierOptionsFieldGroup";

import styles from "./CheckboxClassifierOptionsFieldGroup.module.scss";

export default function CheckboxClassifierOptionsFieldGroup(props: WrappedFieldArrayProps) {
  const { fields } = props;

  const addItem = () => {
    fields.push({
      options: [{}],
    });
  };

  const renderFormItem = (categories: string, index: number, fields: FieldArrayFieldsProps<string>) => {
    function removeItem() {
      fields.remove(index);
    }

    return (
      <div key={index}>
        <div className={styles.fieldWrapper}>
          <Field
            name={`${categories}.category`}
            component={SemanticFormField}
            as={Input}
            type="text"
            placeholder={`Category ${index + 1}`}
            validate={[requiredName]}
          />
          <div className={styles.removeButtonWrapper}>
            <Button onClick={removeItem} negative={true} type="button">
              Remove
            </Button>
          </div>
        </div>
        <div className={styles.optionsWrapper}>
          <FieldArray name={`${categories}.options`} component={DropdownClassifierOptionsFieldGroup} />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.root}>
      <Header as="h4">Category</Header>
      <div className="form-items">{fields.map(renderFormItem)}</div>
      <Button onClick={addItem} type="button">
        Add Category
      </Button>
    </div>
  );
}
