import React, { Component } from "react";
import { Store } from "redux";
import { Button, Header, Loader, Message, Modal, Tab, TabProps } from "semantic-ui-react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";

import { ActionType, DataSetType, ErrorType, ProjectsInfoType } from "types/common";

import {
  deleteCurrentProject,
  fetchCurrentProject,
  selectCurrentIsProjectFetching,
  selectCurrentProject,
} from "ducks/currentProject";
import {
  fetchCurrentProjectDataSet,
  fetchLabeledDataSet,
  selectCurrentDataSet,
  selectCurrentDataSetIsFetching,
  selectLabeledDataSet,
  fetchReviewedDataSet,
  selectReviewedDataSet
} from "ducks/dataset";
import DatasetForm from "components/DatasetForm/DatasetForm";
import ClassifiersSettings from "components/ClassifiersSettings/ClassifiersSettings";
import ProjectUsers from "components/ProjectUsers/ProjectUsers";
import { fetchLabeledData, selectLabeledDataError } from "ducks/labeledData";
import PrivateComponent from "components/PrivateComponent/PrivateComponent";

import styles from "./ProjectInfo.module.scss";

interface Props extends RouteComponentProps<{ id: string }> {
  currentDataSetIsFetching: boolean;
  currentIsProjectFetching: boolean;
  currentProject: ProjectsInfoType | null;
  dataset: DataSetType[] | null;
  labeledDataset: DataSetType[] | null;
  reviewedDataset: DataSetType[] | null;
  fetchCurrentProject: (id: string) => ActionType;
  fetchCurrentProjectDataSet: (id: string) => ActionType;
  fetchLabeledDataSet: (id: string) => ActionType;
  fetchReviewedDataSet: (id: string) => ActionType;
  fetchLabeledData: (id: string) => ActionType;
  deleteCurrentProject: (id: string) => ActionType;
  saveLabeledDataError: ErrorType | null;
}

interface State {
  activeIndex?: number | string;
  isDeleteModalActive: boolean;
}

class ProjectInfo extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      activeIndex: 0,
      isDeleteModalActive: false,
    };
    
  }

  public componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchCurrentProject(id);
    this.props.fetchCurrentProjectDataSet(id);
    this.props.fetchLabeledDataSet(id);
    this.props.fetchReviewedDataSet(id);
    

    console.log('==================')
    console.log('==================')
    let d  = this.props.fetchCurrentProject(id)
    console.log(Object.keys(d))
    console.log(d.payload)
    console.log(this.props)
    console.log(this.props)
    console.log('==================')
    console.log('==================')


  }

  public onStartReviewingClick = () => {
    this.props.history.push(`/reviewing/${this.props.currentProject!._id}/reviewed`);
  };
  public onStartApprovingingClick = () => {
    this.props.history.push(`/reviewing/${this.props.currentProject!._id}/approved`);
  };

  public onStartLabelingClick = () => {
    this.props.history.push(`/labeling/${this.props.currentProject!._id}`);
  };

  public onLoadLabelData = () => {
    this.props.fetchLabeledData(this.props.currentProject!._id);
  };

  public toggleDeleteModal = (show: boolean) => () => this.setState({ isDeleteModalActive: show });

  public handleTabChange = (event: any, { activeIndex }: TabProps) => this.setState({ activeIndex: activeIndex || 0 });

  public deleteProject = () => {
    this.props.deleteCurrentProject(this.props.currentProject!._id);
  };

  public renderPanesByRole = (
    panes: {
      menuItem: string;
      render?: () => React.ReactNode;
    }[]
  ) => {
    const currentAccess = this.props.currentProject?.access;
    

    if (currentAccess !== "admin" && currentAccess !== "editor") {
      return [];
    } else if (currentAccess === "admin") {
      return panes;
    } else if (currentAccess === "editor") {
      // Remove User management access for editor
      return panes.slice(1, panes.length);
    }
  };

  public render() {
    const { currentProject, saveLabeledDataError } = this.props;

    console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
    console.log(this.props.reviewedDataset)
    console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')

    const panes = [
      {
        menuItem: "Users",
        render: () => (
          <ProjectUsers currentUsers={currentProject?.user_access || []} projectId={this.props.currentProject?._id!} />
        ),
      },
      {
        menuItem: "Classifiers",
        render: () => <ClassifiersSettings currentProject={this.props.currentProject} />,
      },
      { menuItem: "Dataset", render: () => <DatasetForm currentProject={this.props.currentProject} /> },
    ];
    return this.props.currentIsProjectFetching || this.props.currentDataSetIsFetching ? (
      <Loader active={true} />
    ) : currentProject ? (
      <div className={styles.root}>
        <div className={styles.headerWrapper}>
          <Header as="h1">{currentProject.name}</Header>

          {!this.props.dataset?.length && (
            <PrivateComponent rolesToAccess={["admin", "editor", "labeller", "labeler&reviewer"]}>
              <Message info={true}>All images are labeled</Message>
            </PrivateComponent>
          )}

          {!this.props.labeledDataset?.length && (
            <PrivateComponent rolesToAccess={["admin", "editor", "reviewer", "labeler&reviewer"]}>
              <Message info={true}>All images are reviewed</Message>
            </PrivateComponent>
          )}
          {!!saveLabeledDataError && (
            <PrivateComponent rolesToAccess={["admin", "editor"]}>
              <Message negative={true}>{saveLabeledDataError.message || saveLabeledDataError}</Message>
            </PrivateComponent>
          )}
          {this.state.isDeleteModalActive && (
            <PrivateComponent rolesToAccess={["admin", "editor"]}>
              <Modal open={this.state.isDeleteModalActive}>
                <Modal.Header>Delete this project</Modal.Header>
                <Modal.Content>
                  <Modal.Description>
                    <Header>Are you sure yo want to delete {this.props.currentProject?.name}</Header>
                    <p>All labeled data, dataset and classifiers will be lost!</p>
                  </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                  <Button negative={true} onClick={this.toggleDeleteModal(false)}>
                    Nope
                  </Button>
                  <Button positive={true} onClick={this.deleteProject}>
                    Yes
                  </Button>
                </Modal.Actions>
              </Modal>
            </PrivateComponent>
          )}
        </div>
        <div className={styles.actions}>
        <PrivateComponent rolesToAccess={["admin", "editor", "labeler", "labeler&reviewer"]}>
            <Button primary={true} disabled={!this.props.dataset?.length} onClick={this.onStartLabelingClick}>
              Start Labeling
            </Button>
          </PrivateComponent>
          
          <PrivateComponent rolesToAccess={["admin", "editor", "reviewer", "labeler&reviewer"]}>
            <Button primary={true} disabled={!this.props.labeledDataset?.length} onClick={this.onStartReviewingClick}>
              Level 1 Review
            </Button>
          </PrivateComponent>
          <PrivateComponent rolesToAccess={["admin", "approver","editor"]}>
            <Button primary={true}  onClick={this.onStartApprovingingClick}>
              Level 2 Review
            </Button>
          </PrivateComponent>
          <PrivateComponent rolesToAccess={["admin", "editor"]}>
            <Button primary={true} onClick={this.onLoadLabelData}>
              Download Labeled Data
            </Button>
          </PrivateComponent>
          <PrivateComponent rolesToAccess={["admin"]}>
            <Button negative={true} onClick={this.toggleDeleteModal(true)}>
              Delete Project
            </Button>
          </PrivateComponent>
        </div>
        {(currentProject.access === "admin" || currentProject.access === "editor") && (
          <Tab
            activeIndex={this.state.activeIndex}
            onTabChange={this.handleTabChange}
            panes={this.renderPanesByRole(panes)}
          />
        )}
      </div>
    ) : null;
  }
}

const mapStateToProps = (state: Store) => ({
  currentDataSetIsFetching: selectCurrentDataSetIsFetching(state),
  currentIsProjectFetching: selectCurrentIsProjectFetching(state),
  currentProject: selectCurrentProject(state),
  dataset: selectCurrentDataSet(state),
  labeledDataset: selectLabeledDataSet(state),
  reviewedDataset: selectReviewedDataSet(state),
  saveLabeledDataError: selectLabeledDataError(state),
});

const mapDispatchToProps = {
  fetchCurrentProjectDataSet,
  fetchLabeledDataSet,
  fetchReviewedDataSet,
  fetchCurrentProject,
  fetchLabeledData,
  deleteCurrentProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProjectInfo));
