import React from "react";
import { Segment } from "semantic-ui-react";

import SignInForm from "components/SignInForm/SignInForm";

import styles from "./Auth.module.scss";

function Auth() {
  return (
    <div className={styles.root}>
      <div className={styles.formWrapper}>
        <Segment>
          <SignInForm />
        </Segment>
      </div>
    </div>
  );
}

export default Auth;
