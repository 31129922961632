import React from "react";
import { Store } from "redux";
import { connect } from "react-redux";
import { Button, Form, Input, Message } from "semantic-ui-react";
import { RouteComponentProps, withRouter } from "react-router";
import { Field, InjectedFormProps, reduxForm } from "redux-form";

import { FORMS, ROUTES } from "utils/constants";
import { maxLength256, requiredName } from "utils/validation";
import { ActionType, FormSubmitActionPayloadType } from "types/common";
import SemanticFormField from "components/SemanticFormField/SemanticFormField";
import { formSubmitCreateProject, selectIsProjectsFetching } from "ducks/projects";

import styles from "components/SignInForm/SignInForm.module.scss";

interface OwnFormProps {
  projectsIsFetching: boolean;
  formSubmitCreateProject: (payload: FormSubmitActionPayloadType<FormData>) => ActionType;
}

export interface FormData {
  name?: string;
}

interface ComponentProps extends RouteComponentProps, OwnFormProps, InjectedFormProps<FormData, OwnFormProps> {}

function CreateProjectForm(props: ComponentProps) {
  const { handleSubmit, projectsIsFetching, error } = props;
  const onSubmit = (formData: FormData) => {
    return new Promise((resolve, reject) =>
      props.formSubmitCreateProject({
        formData,
        reject,
        resolve,
      })
    ).then(() => props.history.push(ROUTES.projects));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className={styles.root}>
      <Form.Field>
        <label>Name</label>
        <Field
          name="name"
          component={SemanticFormField}
          as={Input}
          type="text"
          placeholder="Example"
          validate={[requiredName, maxLength256]}
        />
      </Form.Field>
      {!!error && <Message negative={true}>{error}</Message>}
      <div className={styles.submitContainer}>
        <Button type="submit" primary={true} loading={projectsIsFetching}>
          Submit
        </Button>
      </div>
    </Form>
  );
}

const reduxFormConfig = {
  form: FORMS.CREATE_PROJECT_FORM,
};

const FormCreateProject = reduxForm<FormData, OwnFormProps>(reduxFormConfig)(withRouter(CreateProjectForm));

const mapStateToProps = (state: Store) => ({
  projectsIsFetching: selectIsProjectsFetching(state),
});

const mapDispatchToProps = {
  formSubmitCreateProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormCreateProject);
