import React, { Component } from "react";
import { Button, Card, Divider, Header, Icon, List, Message } from "semantic-ui-react";
import { connect } from "react-redux";
import { Store } from "redux";

import CreateClassifierForm from "components/CreateClassifierForm/CreateClassifierForm";
import {
  ActionType,
  CheckboxAndRadioClassifierOptionsType,
  DeleteClassifiersArguments,
  ErrorType,
  ProjectsInfoType,
} from "types/common";
import { deleteClassifiers, selectClassifiersError } from "ducks/classifiers";

import styles from "./ClassifiersSettings.module.scss";

interface Props {
  error: ErrorType | null;
  currentProject: ProjectsInfoType | null;
  deleteClassifiers: (payload: DeleteClassifiersArguments) => ActionType;
}

interface State {
  classifierToEdit?: string | null;
  classifierOptionsToShow: string | null;
}

class ClassifiersSettings extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      classifierToEdit: undefined,
      classifierOptionsToShow: null,
    };
  }

  public addClassifier = () => this.setState({ classifierToEdit: null });
  public editClassifier = (id: string) => () => this.setState({ classifierToEdit: id });
  public resetClassifierEdit = () => this.setState({ classifierToEdit: undefined });

  public showClassifierOptions = (id: string) => () => {
    const { classifierOptionsToShow } = this.state;
    this.setState({ classifierOptionsToShow: classifierOptionsToShow === id ? null : id });
  };

  public deleteClassifier = (classifierId: string) => () => {
    this.props.deleteClassifiers({
      classifier_id: classifierId,
      project_id: this.props.currentProject?._id!,
    });
  };

  public renderClassifiersOptions = (type: string, options: string[] | CheckboxAndRadioClassifierOptionsType[]) => {
    if (type === "dropdown") {
      return (options as string[]).map((option: string) => {
        return <List.Item key={option}>{option}</List.Item>;
      });
    } else {
      return (options as CheckboxAndRadioClassifierOptionsType[]).map(
        (option: CheckboxAndRadioClassifierOptionsType) => {
          return (
            <List.Item key={option.category}>
              <List.Header>{option.category}</List.Header>
              {option.options.map((item) => (
                <div className={styles.option} key={option.category + item}>
                  {item}
                </div>
              ))}
            </List.Item>
          );
        }
      );
    }
  };

  public renderClassifiersList = () => {
    const classifiers = this.props.currentProject?.classifiers;
    if (!classifiers) {
      return null;
    }

    return classifiers.map((item) => {
      return (
        <Card key={item._id}>
          <div className={styles.classifierHeader}>
            <div className={styles.classifierRow}>
              <span>Name: </span> <span className={styles.classifierName}>{item.name}</span>
            </div>
            <div className={styles.classifierRow}>
              <span>Required: </span> <span className={styles.classifierName}>{item.required ? "Yes" : "No"}</span>
            </div>
            <div className={styles.classifierRow}>
              <span>Type: </span> <span className={styles.classifierName}>{item.type}</span>
            </div>
            <div className={styles.classifierActions}>
              <Button onClick={this.editClassifier(item._id)}>Update</Button>
              <Button negative={true} onClick={this.deleteClassifier(item._id)}>
                Delete
              </Button>
            </div>
            <Divider />
            <div>
              <Header as="h4" className={styles.optionsHeader} onClick={this.showClassifierOptions(item._id)}>
                Options <Icon name="dropdown" />
              </Header>
              {this.state.classifierOptionsToShow === item._id && (
                <List>{this.renderClassifiersOptions(item.type, item.options)}</List>
              )}
            </div>
          </div>
        </Card>
      );
    });
  };

  render() {
    const { error } = this.props;
    const { classifierToEdit } = this.state;
    return (
      <div className={styles.root}>
        {classifierToEdit === undefined && (
          <>
            {!!error && <Message negative={true}>{error.message || error}</Message>}
            <div className={styles.header}>
              <Header as="h3" className={styles.headerText}>
                Current classifiers
              </Header>
              <Button primary={true} onClick={this.addClassifier}>
                Add new
              </Button>
            </div>
            <Card.Group className={styles.classifiersList}>{this.renderClassifiersList()}</Card.Group>
          </>
        )}
        {classifierToEdit !== undefined && (
          <Card fluid={true} className={styles.formWrapper}>
            <div className={styles.header}>
              <Header as="h3" className={styles.headerText}>
                Add classifier
              </Header>
              <Button onClick={this.resetClassifierEdit}>Back</Button>
            </div>
            <CreateClassifierForm classifierToEdit={classifierToEdit} currentProject={this.props.currentProject} />
          </Card>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: Store) => ({
  error: selectClassifiersError(state),
});

const mapDispatchToProps = {
  deleteClassifiers,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassifiersSettings);
