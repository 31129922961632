import React from "react";
import { Field } from "redux-form";
import { Checkbox, Divider, Dropdown, Radio } from "semantic-ui-react";

import { arrayOfStringsToOptionList } from "utils/helpers";
import SemanticFormField from "components/SemanticFormField/SemanticFormField";
import { CheckboxAndRadioClassifierOptionsType, ClassifiersType } from "types/common";

import styles from "./DynamicInput.module.scss";

interface Props {
  classifier: ClassifiersType;
}

export default function DynamicInput(props: Props) {
  const { classifier } = props;

  const normalizeCheckboxValue = (category: string) => (optionName: string) => (value: any) => {
    if (!!value) {
      return {
        category,
        value: optionName,
        classifierId: classifier._id,
        classifierName: classifier.name,
        type: classifier.type,
      };
    } else {
      return false;
    }
  };

  const normalizeRadiobuttonValue = (category: string) => (optionName: string) => (value: any) => {
    if (!!value) {
      return {
        category,
        value: optionName,
        classifierId: classifier._id,
        classifierName: classifier.name,
        type: classifier.type,
      };
    } else {
      return undefined;
    }
  };

  const formatCheckboxValue = (value: any) => {
    return !!value;
  };

  const isChecked = (label: string) => (value: any) => {
    return label === value.value;
  };

  if (classifier.type === "dropdown") {
    return (
      <div className="field" key={classifier._id}>
        <Divider />
        <label>{classifier.name}</label>
        <Divider />
        <Field
          name={classifier.name}
          component={SemanticFormField}
          as={Dropdown}
          fluid={true}
          selection={true}
          options={arrayOfStringsToOptionList(classifier.options as string[])}
        />
      </div>
    );
  } else if (classifier.type === "checkbox") {
    return (
      <div className="field">
        <Divider />
        <label>{classifier.name}</label>
        <Divider />
        {(classifier.options as CheckboxAndRadioClassifierOptionsType[]).map((item) => {
          console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>')
          console.log(item)
          console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>')
          return (
            <div className="field" key={item.category}>
              <div className={styles.category}>{item.category}</div>
              {item.options.map((inputData: string) => {
                return (
                  <Field
                    label={inputData}
                    key={inputData}
                    name={inputData}
                    normalize={normalizeCheckboxValue(item.category)(inputData)}
                    format={formatCheckboxValue}
                    semanticSimpleInput={true}
                    component={SemanticFormField}
                    type="checkbox"
                    as={Checkbox}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    );
  } else if (classifier.type === "radiobutton") {
    return (
      <div className="field">
        <Divider />
        <label>{classifier.name}</label>
        <Divider />
        {(classifier.options as CheckboxAndRadioClassifierOptionsType[]).map(
          (item: CheckboxAndRadioClassifierOptionsType) => {
            return (
              <div className="field" key={item.category}>
                <div className={styles.category}>{item.category}</div>
                {item.options.map((inputData: string) => {
                  return (
                    <Field
                      label={inputData}
                      key={inputData}
                      name={classifier.name}
                      normalize={normalizeRadiobuttonValue(item.category)(inputData)}
                      semanticSimpleInput={true}
                      checked={isChecked(inputData)}
                      component={SemanticFormField}
                      radio={true}
                      as={Radio}
                    />
                  );
                })}
              </div>
            );
          }
        )}
      </div>
    );
  }
  return null;
}
