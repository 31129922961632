import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Field, InjectedFormProps, reduxForm, formValueSelector, FieldArray } from "redux-form";
import { Store } from "redux";
import { Button, Checkbox, Dropdown, Form, Input, Message } from "semantic-ui-react";
import { connect } from "react-redux";

import { FORMS } from "utils/constants";
import SemanticFormField from "components/SemanticFormField/SemanticFormField";
import { requiredField, requiredName } from "utils/validation";
import {
  ActionType,
  CheckboxAndRadioClassifierOptionsType,
  FormSubmitActionPayloadType,
  ProjectsInfoType,
} from "types/common";
import { formSubmitClassifiers } from "ducks/classifiers";
import DropdownClassifierOptionsFieldGroup from "components/DropdownClassifierOptionsFieldGroup/DropdownClassifierOptionsFieldGroup";
import CheckboxClassifierOptionsFieldGroup from "components/CheckboxClassifierOptionsFieldGroup/CheckboxClassifierOptionsFieldGroup";
import { fetchCurrentProject, selectCurrentProject } from "ducks/currentProject";

import styles from "./CreateClassifierForm.module.scss";

const TypesOptions = [
  {
    text: "Dropdown",
    value: "dropdown",
  },
  {
    text: "Checkbox",
    value: "checkbox",
  },
  {
    text: "Radio Button",
    value: "radiobutton",
  },
];

interface OwnFormProps {
  classifierToEdit: string | null;
  typeValue: string;
  currentProject: ProjectsInfoType | null;
  formSubmitClassifiers: (payload: FormSubmitActionPayloadType<NormalizedFormData>) => ActionType;
  fetchCurrentProject: (payload: string) => ActionType;
}

type DropdownOption = {
  option: string;
};

type CheckBoxOption = {
  category: string;
  options: DropdownOption[];
};

type NormalizedDropdownOption = string;

type NormalizedCheckBoxOption = {
  category: string;
  options: string[];
};

export interface FormData {
  name?: string;
  required?: boolean;
  type?: string;
  project_id?: string;
  options: DropdownOption[];
  categories: CheckBoxOption[];
}

export interface NormalizedFormData {
  name?: string;
  required?: boolean;
  type?: string;
  project_id?: string;
  options?: NormalizedDropdownOption[] | NormalizedCheckBoxOption[];
  classifierToEdit?: string | null;
}

interface ComponentProps extends RouteComponentProps, OwnFormProps, InjectedFormProps<FormData, OwnFormProps> {}

class CreateClassifierForm extends Component<ComponentProps> {
  public onDropdownChange = (change: any) => (value: any) => {
    const classifier = this.props.currentProject?.classifiers.find((item) => item._id === this.props.classifierToEdit);
    if (this.props.classifierToEdit && value === classifier?.type) {
      if (value === "dropdown") {
        change("categories", undefined);
        change(
          "options",
          (classifier?.options as string[]).map((item) => ({
            option: item,
          }))
        );
      } else if (value !== "dropdown") {
        change("options", undefined);
        change(
          "categories",
          (classifier?.options as CheckboxAndRadioClassifierOptionsType[]).map((item) => ({
            category: item.category,
            options: item.options.map((option) => ({
              option,
            })),
          }))
        );
      }
    } else {
      if (value === "dropdown") {
        change("categories", undefined);
        change("options", [{}, {}]);
      } else if (value !== "dropdown") {
        change("options", undefined);
        change("categories", [
          {
            options: [{}],
          },
        ]);
      }
    }
  };

  public onSubmit = (formData: FormData) => {
    const data: NormalizedFormData = {
      name: formData.name,
      required: formData.required || false,
      type: formData.type,
      project_id: this.props.currentProject?._id,
      options: undefined,
      classifierToEdit: this.props.classifierToEdit,
    };

    if (formData.type === "dropdown") {
      data.options = (formData.options as DropdownOption[]).map((item) => item.option);
    } else {
      data.options = (formData.categories as CheckBoxOption[]).map((item) => {
        return {
          category: item.category,
          options: item.options.map((item: { option: string }) => item.option),
        };
      });
    }

    return new Promise((resolve, reject) =>
      this.props.formSubmitClassifiers({
        formData: data,
        reject,
        resolve,
      })
    ).then(() => this.props.fetchCurrentProject(this.props.currentProject?._id!));
  };
  public render() {
    const { handleSubmit, error, typeValue } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.onSubmit)} className={styles.root}>
        <Form.Field>
          <label>Name</label>
          <Field
            name="name"
            component={SemanticFormField}
            as={Input}
            type="text"
            placeholder="Name of field"
            validate={[requiredName]}
          />
        </Form.Field>
        <Form.Field>
          <Field
            label="This field is required"
            name="required"
            component={SemanticFormField}
            placeholder="Name of field"
            semanticSimpleInput={true}
            type="checkbox"
            as={Checkbox}
          />
        </Form.Field>
        <Form.Field>
          <label>Type</label>
          <Field
            name="type"
            component={SemanticFormField}
            as={Dropdown}
            onChange={this.onDropdownChange(this.props.change)}
            placeholder="Type of input"
            validate={[requiredField]}
            selection={true}
            options={TypesOptions}
          />
        </Form.Field>
        {typeValue === "dropdown" && <FieldArray name="options" component={DropdownClassifierOptionsFieldGroup} />}
        {typeValue === "checkbox" && <FieldArray name="categories" component={CheckboxClassifierOptionsFieldGroup} />}
        {typeValue === "radiobutton" && (
          <FieldArray name="categories" component={CheckboxClassifierOptionsFieldGroup} />
        )}
        {!!error && <Message negative={true}>{error}</Message>}
        <Button type="submit" primary={true} loading={false}>
          Submit
        </Button>
      </Form>
    );
  }
}

const reduxFormConfig = {
  form: FORMS.CREATE_CLASSIFIER_FORM,
};

const FormCreateClassifier = reduxForm<FormData, OwnFormProps>(reduxFormConfig)(withRouter(CreateClassifierForm));

const mapStateToProps = (state: Store, props: any) => {
  const formSelector = formValueSelector(FORMS.CREATE_CLASSIFIER_FORM);
  const initialValues = {};

  if (props.classifierToEdit) {
    const project = selectCurrentProject(state);
    const classifier = project?.classifiers.find((item) => item._id === props.classifierToEdit);

    Object.assign(initialValues, {
      name: classifier?.name,
      required: classifier?.required,
      type: classifier?.type,
    });

    if (classifier?.type === "dropdown") {
      Object.assign(initialValues, {
        options: (classifier.options as string[]).map((item) => ({
          option: item,
        })),
      });
    } else if (!!classifier && classifier?.type !== "dropdown") {
      Object.assign(initialValues, {
        categories: (classifier.options as CheckboxAndRadioClassifierOptionsType[]).map((item) => ({
          category: item.category,
          options: item.options.map((option) => ({
            option,
          })),
        })),
      });
    }
  }

  return {
    initialValues,
    typeValue: formSelector(state, "type"),
  };
};

const mapDispatchToProps = { formSubmitClassifiers, fetchCurrentProject };

export default connect(mapStateToProps, mapDispatchToProps)(FormCreateClassifier);
