import React, { Component, SyntheticEvent } from "react";
import { Card, Divider, Input } from "semantic-ui-react";
import { InputOnChangeData } from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import { Link } from "react-router-dom";

import { ProjectsType } from "types/common";

import styles from "./ProjectsList.module.scss";

interface Props {
  projects: ProjectsType[];
}

interface State {
  filteredProjects: ProjectsType[];
}

export default class ProjectsList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      filteredProjects: this.props.projects,
    };
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (!Object.is(prevProps.projects, this.props.projects)) {
      this.setState({ filteredProjects: this.props.projects });
    }
  }

  public renderProjects = () =>
    this.state.filteredProjects.map((project) => (
      <Link to={`project/${project._id}`} key={project._id}>
        <Card.Group>
          <Card fluid={true} header={project.name} />
        </Card.Group>
      </Link>
    ));

  public onSearchChange = (event: SyntheticEvent, data: InputOnChangeData) => {
    this.setState({
      filteredProjects: this.props.projects
        .slice()
        .filter((item) => item.name.toLowerCase().indexOf(data.value.toLowerCase()) !== -1),
    });
  };

  render() {
    return (
      <>
        <div className={styles.inputContainer}>
          <Input placeholder="Search..." fluid={true} onChange={this.onSearchChange} />
        </div>
        <Divider />
        {this.renderProjects()}
      </>
    );
  }
}
