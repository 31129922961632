import { API_URI, appName } from "utils/constants";
import { ActionType, AddUserToProjectArguments, DeleteUserToProjectArguments, ErrorType } from "types/common";
import { Store } from "redux";
import { createSelector } from "reselect";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { callApi } from "redux/api";
import { fetchCurrentProject } from "ducks/currentProject";

/* Constants */

export const moduleName = "currentProjectUsers";

const prefix = `${appName}/${moduleName}`;

export const PUT_USER_TO_PROJECT_REQUEST = `${prefix}/PUT_USER_TO_PROJECT_REQUEST`;
export const PUT_USER_TO_PROJECT_SUCCESS = `${prefix}/PUT_USER_TO_PROJECT_SUCCESS`;
export const PUT_USER_TO_PROJECT_FAILURE = `${prefix}/PUT_USER_TO_PROJECT_FAILURE`;

export const PUT_USER_PROJECT_ROLE_REQUEST = `${prefix}/PUT_USER_PROJECT_ROLE_REQUEST`;
export const PUT_USER_PROJECT_ROLE_SUCCESS = `${prefix}/PUT_USER_PROJECT_ROLE_SUCCESS`;
export const PUT_USER_PROJECT_ROLE_FAILURE = `${prefix}/PUT_USER_PROJECT_ROLE_FAILURE`;

export const DELETE_USER_FROM_PROJECT_REQUEST = `${prefix}/DELETE_USER_FROM_PROJECT_REQUEST`;
export const DELETE_USER_FROM_PROJECT_SUCCESS = `${prefix}/DELETE_USER_FROM_PROJECT_SUCCESS`;
export const DELETE_USER_FROM_PROJECT_FAILURE = `${prefix}/DELETE_USER_FROM_PROJECT_FAILURE`;

/* Reducer */

interface State {
  error: ErrorType | null;
  isFetching: boolean;
}

const initialState: State = {
  error: null,
  isFetching: false,
};

export default function reducer(state: State = initialState, action: ActionType) {
  const { type, error } = action;

  switch (type) {
    case PUT_USER_TO_PROJECT_REQUEST:
    case PUT_USER_PROJECT_ROLE_REQUEST:
    case DELETE_USER_FROM_PROJECT_REQUEST:
      return Object.assign({}, state, {
        error: null,
        isFetching: true,
      });

    case PUT_USER_TO_PROJECT_SUCCESS:
    case PUT_USER_PROJECT_ROLE_SUCCESS:
    case DELETE_USER_FROM_PROJECT_SUCCESS:
      return Object.assign({}, state, {
        error: null,
        isFetching: false,
      });

    case PUT_USER_TO_PROJECT_FAILURE:
    case PUT_USER_PROJECT_ROLE_FAILURE:
    case DELETE_USER_FROM_PROJECT_FAILURE:
      return Object.assign({}, state, {
        error,
        isFetching: false,
      });

    default:
      return state;
  }
}

/*Selectors */

const stateSelector = (state: Store) => state[moduleName];

export const selectCurrentProjectUsersIsFetching = createSelector(stateSelector, (state: State) => state.isFetching);

export const selectCurrentProjectUsersError = createSelector(stateSelector, (state: State) => state.error);

/* Actions */

export function putUserToProject(payload: AddUserToProjectArguments) {
  return {
    payload,
    type: PUT_USER_TO_PROJECT_REQUEST,
  };
}
export function putUserToProjectSuccess() {
  return {
    type: PUT_USER_TO_PROJECT_SUCCESS,
  };
}
export function putUserToProjectFailure(error: ErrorType) {
  return {
    error,
    type: PUT_USER_TO_PROJECT_FAILURE,
  };
}

export function putUserProjectRole(payload: AddUserToProjectArguments) {
  return {
    payload,
    type: PUT_USER_PROJECT_ROLE_REQUEST,
  };
}
export function putUserProjectRoleSuccess() {
  return {
    type: PUT_USER_PROJECT_ROLE_SUCCESS,
  };
}
export function putUserProjectRoleFailure(error: ErrorType) {
  return {
    error,
    type: PUT_USER_PROJECT_ROLE_FAILURE,
  };
}

export function deleteUserFromProject(payload: DeleteUserToProjectArguments) {
  return {
    payload,
    type: DELETE_USER_FROM_PROJECT_REQUEST,
  };
}
export function deleteUserFromProjectSuccess() {
  return {
    type: DELETE_USER_FROM_PROJECT_SUCCESS,
  };
}
export function deleteUserFromProjectFailure(error: ErrorType) {
  return {
    error,
    type: DELETE_USER_FROM_PROJECT_FAILURE,
  };
}

/* Sagas */

function* putUserToProjectSaga({ payload }: ActionType): SagaIterator {
  try {
    const { projectId } = payload;
    yield call(ApiFetchCurrentProjectUsers, payload, projectId);
    yield put(putUserToProjectSuccess());
    yield put(fetchCurrentProject(projectId));
  } catch (error) {
    yield put(putUserToProjectFailure(error));
  }
}

function* deleteUserFromProjectSaga({ payload }: ActionType): SagaIterator {
  try {
    const { projectId } = payload;
    yield call(ApiDeleteUserFromProject, payload, projectId);
    yield put(deleteUserFromProjectSuccess());
    yield put(fetchCurrentProject(projectId));
  } catch (error) {
    yield put(deleteUserFromProjectFailure(error));
  }
}

function* putUserProjectRoleSaga({ payload }: ActionType): SagaIterator {
  try {
    const { projectId } = payload;
    yield call(ApiPutUserProjectRole, payload, projectId);
    yield put(putUserProjectRoleSuccess());
    yield put(fetchCurrentProject(projectId));
  } catch (error) {
    yield put(putUserProjectRoleFailure(error));
  }
}

export const saga = function* () {
  yield all([
    takeEvery(PUT_USER_TO_PROJECT_REQUEST, putUserToProjectSaga),
    takeEvery(DELETE_USER_FROM_PROJECT_REQUEST, deleteUserFromProjectSaga),
    takeEvery(PUT_USER_PROJECT_ROLE_REQUEST, putUserProjectRoleSaga),
  ]);
};

/* API */

function* ApiFetchCurrentProjectUsers(data: AddUserToProjectArguments, projectId: string) {
  delete data.projectId;
  return yield call(callApi, API_URI + "projects/" + projectId + "/adduser", data, "POST");
}

function* ApiDeleteUserFromProject(data: DeleteUserToProjectArguments, projectId: string) {
  delete data.projectId;
  return yield call(callApi, API_URI + "projects/" + projectId + "/deleteuser", data, "DELETE");
}

function* ApiPutUserProjectRole(data: AddUserToProjectArguments, projectId: string) {
  delete data.projectId;
  return yield call(callApi, API_URI + "projects/" + projectId + "/updateuser", data, "PUT");
}
