import React, { Component } from "react";
import { Button, Dropdown, Table } from "semantic-ui-react";

import { ActionType, AddUserToProjectArguments, UserType } from "types/common";
import { DropdownProps } from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";
import { ACCESS_LEVELS } from "utils/constants";

interface Props {
  user: UserType;
  addUser: (payload: AddUserToProjectArguments) => ActionType;
  currentProjectId: string;
}

interface State {
  access: string;
}

class AddUserLitsItem extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      access: "",
    };
  }

  public addUserToProject = () => {
    const { access } = this.state;
    if (access) {
      const { user, currentProjectId } = this.props;
      this.props.addUser({
        projectId: currentProjectId,
        user_id: user.user_id,
        email: user.email,
        access_type: access,
      });
    }
  };

  public onAccessChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) =>
    this.setState({ access: data.value as string });

  public render() {
    const { user } = this.props;
    return (
      <Table.Row key={user.user_id}>
        <Table.Cell>{user.email}</Table.Cell>
        {/*TODO: Backend must replace user.access from string[] to just string*/}
        <Table.Cell>
          <Dropdown
            selection={true}
            placeholder="Choose role for this user"
            options={ACCESS_LEVELS}
            value={this.state.access}
            onChange={this.onAccessChange}
          />
        </Table.Cell>
        <Table.Cell>
          <Button disabled={!this.state.access} primary={true} onClick={this.addUserToProject}>
            Add to project
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default AddUserLitsItem;
